<template>
  <tr>
    <td>
      <template v-if="concert.temporaryComment">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-icon icon="mdi-comment" v-bind="props"></v-icon>
          </template>
          <span v-html="nl2br(concert.temporaryComment)"></span>
        </v-tooltip>
      </template>
    </td>
    <td>{{ getShortWeekday(concert.date) }}</td>
    <td>{{ concert.date }}</td>
    <td>{{ concert.begin }}</td>
    <td>{{ concert.venueObj?.address?.city }}</td>
    <td>{{ concert.venueObj?.nameWithoutCity }}</td>
    <td>{{ concert.capacity }}</td>
    <td>{{ concert.temporaryType }}</td>
    <td>
      <v-select
        v-model="concert.department"
        :items="allDepartments"
        item-title="desc"
        item-value="id"
        :menu-props="{maxHeight: 320, eager: true}"
        style="min-width: 300px; width: 300px;"
        label="Department *"
        return-object
        class="pt-5"
        density="compact"
        :rules="departmentRule"
        required
      ></v-select>
    </td>
    <td>{{ concert.coPromoterObj?.name }}</td>
    <td>{{ concert.selfPromoted ? "ja" : "nein" }}</td>
    <td>{{ concert.flatDeal ? "ja" : "nein" }}</td>
    <td>
      <v-text-field
        v-model="concert.oracleCode"
        label="Oracle-ID *"
        class="pt-5"
        density="compact"
        style="width: 155px"
        :rules="oracleCodeRule"
        required
      >
      </v-text-field>
    </td>
    <td>
      <div class="d-flex">
        <div>
          <v-text-field
            v-model="concert.projectName"
            label="Projektname *"
            class="pt-5"
            density="compact"
            style="width: 255px"
            :rules="projectNameRule"
            required
          >
          </v-text-field>
        </div>
        <div>
          <v-btn
            @click="setProjectnameForAll"
            class="mt-5 ml-2"
            :disabled="!concert.oracleCode"
          >
            <v-icon icon="mdi-bullhorn-variant-outline"></v-icon>

            <v-tooltip
              activator="parent"
              location="top"
            >
              <template v-if="!concert.oracleCode">
                Setzen des Projektnamens bei alle Konzerten mit gleicher Oracle-ID nur möglich wenn eine Oracle-ID gesetzt ist
              </template>
              <template v-else>
                Projektname für alle Konzerte mit gleicher Oracle-ID setzen
              </template>
            </v-tooltip>
          </v-btn>
        </div>
      </div>
    </td>
    <td>
      <div class="d-flex">
        <div>
          <v-select
            v-model="concert.reportMonth"
            :label="getReportMonthLabel()"
            :items="getAllMonthsAsArray()"
            item-title="value"
            item-value="id"
            class="pt-5"
            density="compact"
            style="width: 140px"
            :clearable="true"
          ></v-select>
        </div>
        <div>
          <DebouncedNumberInput
            v-model="concert.reportYear"
            control-variant="split"
            :label="getReportYearLabel()"
            :min="getReportYearMin()"
            :max="getReportMaxYear()"
            density="compact"
            :clearable="true"
            :persistent-counter="true"
            style="width: 200px"
            class="ml-2 pt-5"
          ></DebouncedNumberInput>
        </div>
        <div>
          <v-btn
            @click="setReportMonthAndYearForAll"
            class="mt-5 ml-2"
            :disabled="!concert.oracleCode"
          >
            <v-icon icon="mdi-bullhorn-variant-outline"></v-icon>

            <v-tooltip
              activator="parent"
              location="top"
            >
              <template v-if="!concert.oracleCode">
                Setzen von Report Month / Year bei alle Konzerten mit gleicher Oracle-ID nur möglich wenn eine Oracle-ID gesetzt ist
              </template>
              <template v-else>
                Report Month / Year für alle Konzerte mit gleicher Oracle-ID setzen
              </template>
            </v-tooltip>
          </v-btn>
        </div>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useDateFormatter } from "@/helpers/dateFormatter"
import { ref }              from "vue"
import Concert              from "@/interfaces/Concert"
import { useString }        from "@/helpers/string"
import DebouncedNumberInput from "@/components/DebouncedNumberInput.vue"

const { getShortWeekday, getAllMonthsAsArray, getMonthStringByMonthNumber } = useDateFormatter()
const { nl2br } = useString()

const props = defineProps<{
  concert: Concert,
  allDepartments: any
}>()

const emits = defineEmits(["setProjectnameForAll", "setReportMonthAndYearForAll"])

const setProjectnameForAll = () => {
  emits("setProjectnameForAll", props.concert)
}

const setReportMonthAndYearForAll = () => {
  emits("setReportMonthAndYearForAll", props.concert)
}

const getReportYearLabel = () => {
  if (!props.concert.reportYear && props.concert.date) {
    return props.concert.date.split('.')[2]
  }

  return 'Report Year'
}

const getReportYearMin = () => {
  let concertYear = props.concert.date ? Number(props.concert.date.split('.')[2]) : (new Date().getFullYear())
  let reportYear = props.concert.reportYear ?? (new Date().getFullYear())

  return Math.min(concertYear, reportYear) - 1
}
const getReportMaxYear = () => {
  return Math.max(getReportYearMin(), (new Date().getFullYear())) + 5
}

const getReportMonthLabel = () => {
  if (!props.concert.reportMonth && props.concert.date) {
    return getMonthStringByMonthNumber(Number(props.concert.date.split('.')[1])) ?? 'Report Month'
  }

  return 'Report Month'
}

const oracleCodeRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const departmentRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const projectNameRule = ref([
  v => !!v || "Darf nicht leer sein"
])

</script>
