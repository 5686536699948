<template>
  <v-row v-if="anyNoShowProjectsSelected(incomingInvoice) ||  anyConcertSelected(incomingInvoice)">
    <v-col cols="12">
      <v-card class="mt-6">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card-title bg-color="grey-lighten-2">
                Zuweisungs-Assistent
              </v-card-title>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="costAssignment.group"
                item-title="invoiceGroup.name"
                item-value="id"
                label="Rechnungsgruppe"
                :items="invoiceGroups"
                :menu-props="{maxHeight: 320, eager: true}"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="costAssignment.desc"
                label="Zusatzinfo"
              ></v-text-field>
            </v-col>
            <v-col cols="3" v-if="incomingInvoice.flgUseCostPositionNumber">
              <v-text-field
                v-model="costAssignment.number"
                label="Nummer"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <div class="dp-block">
                <div class="dp-field">
                  <CurrencyInput
                    v-model="costAssignment.amount"
                    :options="{ precision: 6 }"
                    :currency="incomingInvoice.currency"
                    label="Kosten"
                  ></CurrencyInput>
                </div>
                <div class="v-input__details" v-if="amountMessageExists()">
                  <div class="v-messages">
                    <div class="v-messages__message" style="transform-origin: center top 0;">{{
                        getAmountMessage()
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              Betroffene Positionen: {{ countPositionsToAssign() }}
            </v-col>
            <v-col cols="3" v-if="incomingInvoice.flgUseCostPositionNumber">
            </v-col>
            <v-col cols="3">
              Pro Position: {{ formattedAmountPerPosition() }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn
                @click="assignCosts()"
                :disabled="!canAssign()"
              >
                Zuweisen und Aufteilen
              </v-btn>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12">
              <hr />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              Betroffene Positionen für Rücksetzung: {{ countPositionsToReset() }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                @click="reset()"
                :disabled="!canReset()"
              >
                Zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>

import { computed, ref } from "vue"
import IncomingInvoice   from "@/interfaces/IncomingInvoice"
import CostAssignment from "@/interfaces/CostAssignment"
import CurrencyInput from "@/components/CurrencyInput.vue"
import { useIncomingInvoiceFunctions } from "@/helpers/incomingInvoice"
import { useNumberFormatter } from "@/helpers/formatters"

const props = defineProps<{
  incomingInvoice: IncomingInvoice
}>()

const costAssignment = ref({} as CostAssignment)

const {
  calculateSum,
  selectedConcerts,
  selectedConcertsForAssignment,
  anyConcertSelected,
  anyConcertSelectedForAssignment,
  selectedNoShowProjects,
  selectedNoShowProjectsForAssignment,
  anyNoShowProjectsSelected,
  anyNoShowProjectsSelectedForAssignment,
  checkAndSetGroup
} = useIncomingInvoiceFunctions()

const { formatAmountAndCurrency } = useNumberFormatter()

const invoiceGroups = computed(() => {
  return props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
    return !!group.invoiceGroup
  })
})

const positionsToAssign = () => {
  if (!costAssignment.value.group) {
    return []
  }

  let allPositions = []
  selectedConcertsForAssignment(props.incomingInvoice).forEach((concert) => {
    allPositions = allPositions.concat(concert.incomingInvoicePositions)
  })

  selectedNoShowProjectsForAssignment(props.incomingInvoice).forEach((noShowProject) => {
    allPositions = allPositions.concat(noShowProject.incomingInvoicePositions)
  })

  return allPositions.filter((position) => {
    return !position.amount && position.groupId === costAssignment.value.group.invoiceGroup?.id
  })
}

const countPositionsToAssign = () => {
  return positionsToAssign().length
}

const countPositionsToReset = () => {
  let cnt = selectedConcertsForAssignment(props.incomingInvoice).length
  cnt += selectedNoShowProjectsForAssignment(props.incomingInvoice).length

  return cnt
}

const getAmount = () => {
  if (costAssignment.value.amount) {
    return costAssignment.value.amount
  }
  return getRemainingGroupAmount()
}

const calculateAmountPerPosition = () => {
  let count = countPositionsToAssign()
  return ( getAmount() ) / ( count !== 0 ? count : 1 )
}

const formattedAmountPerPosition = () => {
  return formatAmountAndCurrency(calculateAmountPerPosition(), props.incomingInvoice.currency, 6)
}

const getRemainingGroupAmount = () => {
  if (!costAssignment.value.group) {
    return 0
  }

  let groupId = costAssignment.value.group.invoiceGroup?.id
  let groups = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
    return group.invoiceGroup?.id === groupId
  })

  if (groups.length === 0) {
    return 0
  }

  let groupAmount = groups.find((x) => typeof x !== "undefined").amount

  let setPositions = []
  selectedConcerts(props.incomingInvoice).forEach((concert) => {
    setPositions = setPositions.concat(concert.incomingInvoicePositions)
  })

  selectedNoShowProjects(props.incomingInvoice).forEach(item => {
    setPositions = setPositions.concat(item.incomingInvoicePositions)
  })

  setPositions.forEach((position) => {
    if (position.amount && position.groupId === groupId) {
      groupAmount -= position.amount
    }
  })

  return Math.round(groupAmount * 1000000) / 1000000
}

const amountMessageExists = () => {
  return ( !costAssignment.value.amount && !!costAssignment.value.group ) || ( !!costAssignment.value.amount && getRemainingGroupAmount() < costAssignment.value.amount )
}

const getAmountMessage = () => {
  if (!costAssignment.value.amount && !!costAssignment.value.group) {
    return "Da keine Kosten gesetzt sind, werden die übrigen Kosten für die gewählte Gruppe verwendet: " + formatAmountAndCurrency(getRemainingGroupAmount(), props.incomingInvoice.currency, 6)
  }
  if (!!costAssignment.value.amount && getRemainingGroupAmount() < costAssignment.value.amount) {
    return "Die gesetzten Kosten (" + formatAmountAndCurrency(costAssignment.value.amount, props.incomingInvoice.currency, 6) + ") übersteigen die restlichen Kosten der gewählten Gruppe (" + formatAmountAndCurrency(getRemainingGroupAmount(), props.incomingInvoice.currency, 6) + ")!"
  }
}

const assignCosts = () => {
  let allPositions = positionsToAssign()
  if (allPositions.length > 0) {
    let amount = calculateAmountPerPosition()

    allPositions.forEach((position) => {
      position.amount = amount
      if (costAssignment.value.desc) {
        position.desc = costAssignment.value.desc
      }
      if (costAssignment.value.number && props.incomingInvoice.flgUseCostPositionNumber) {
        position.number = costAssignment.value.number
      }
    })
  }

  let groupId = costAssignment.value.group.invoiceGroup?.id
  if (groupId) {
    props.incomingInvoice.incomingInvoiceTours.forEach(tour => {
      tour.incomingInvoiceContainers.forEach(container => {
        if (container.selectedState !== false) {
          calculateSum(container, groupId)
          checkAndSetGroup(container, groupId, 'desc')
          checkAndSetGroup(container, groupId, 'number')
        }
      })
    })
  }
}

const canAssign = () => {
  return (anyConcertSelectedForAssignment(props.incomingInvoice) || anyNoShowProjectsSelectedForAssignment(props.incomingInvoice))
    && !!costAssignment.value.group && getAmount() !== 0
}

const canReset = () => {
  return anyConcertSelectedForAssignment(props.incomingInvoice)
    + anyNoShowProjectsSelectedForAssignment(props.incomingInvoice)
}

const reset = () => {
  selectedConcertsForAssignment(props.incomingInvoice).forEach(concert => {
    concert.incomingInvoicePositions.forEach(pos => {
      pos.number = null
      pos.desc = null
      pos.amount = null
    })
  })

  selectedNoShowProjectsForAssignment(props.incomingInvoice).forEach(item => {
    item.incomingInvoicePositions.forEach(pos => {
      pos.number = null
      pos.desc = null
      pos.amount = null
    })
  })

  props.incomingInvoice.incomingInvoiceTours.forEach(tour => {
    tour.incomingInvoiceContainers.forEach(container => {
      if (container.selectedStateForCostAssignment === true || container.selectedStateForCostAssignment === null) {
        props.incomingInvoice.incomingInvoiceGroups.forEach(group => {
          if (group.invoiceGroup && group.invoiceGroup.id) {
            calculateSum(container, group.invoiceGroup.id)
            checkAndSetGroup(container, group.invoiceGroup.id, 'desc')
            checkAndSetGroup(container, group.invoiceGroup.id, 'number')
          }
        })
      }
    })
  })
}
</script>

<style scoped>

</style>
