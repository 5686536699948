import axios           from "axios"
import BaseApiService  from "./BaseApiService"
import IncomingInvoice from "../interfaces/IncomingInvoice"

export default class IncomingInvoiceService extends BaseApiService {

  public async sendAndSave(incomingInvoice: IncomingInvoice): Promise<any> {
    return await this.makeRequest("post", "/incoming-invoice/send-and-save", null, { incomingInvoice: incomingInvoice })
  }

  public async checkInvoicePdf(incomingInvoice: IncomingInvoice): Promise<any> {
    return await this.makeRequest("post", "/incoming-invoice/check-invoice-pdf", null, { incomingInvoice: incomingInvoice })
  }

  public async getGroups(): Promise<any> {
    return await this.makeRequest("get", "/invoice-group/get-groups", null, null)
  }

  public async findTours(searchString: string): Promise<any> {
    return await this.makeRequest("get", "/incomingInvoice/tour/index", { searchString: searchString }, null)
  }

  public async getTour(id: number): Promise<any> {
    return await this.makeRequest("get", "/incomingInvoice/tour/view", {
      id: id,
      expand: "incomingInvoiceContainers"
    }, null)
  }

  public async findCompanies(searchString: string): Promise<any> {
    return await this.makeRequest("get", "/incomingInvoice/company/find-companies", {
      searchString: searchString,
      expand: "address"
    }, null)
  }

  public async findPossibleRecipientCompanies(): Promise<any> {
    return await this.makeRequest("get", "/incoming-invoice/find-possible-recipient-companies", null, null)
  }

  public async findCompanyPersonRelations(searchString: string): Promise<any> {
    return await this.makeRequest("get", "/incomingInvoice/company-person-relation/find-company-person-relations", { searchString: searchString }, null)
  }

  public async checkDuplicateInvoiceNumberAndCompany(invoiceNumber: string, invoiceCompanyId: number): Promise<any> {
    return await this.makeRequest("get", "/incoming-invoice/check-duplicate-by-invoice-number-and-company", {
      invoiceNumber: invoiceNumber,
      comId: invoiceCompanyId
    }, null)
  }

  public async getIncomingInvoiceData(iinId: number): Promise<any> {
    return await this.makeRequest("get", "/incoming-invoice/get-invoice", {
      id: iinId,
      expand: "invoicePdf,attachments,incomingInvoiceGroups,incomingInvoiceTours,incomingInvoiceTours.incomingInvoiceContainers,copyRecipients,approvingPersons,incomingInvoiceNoShowProjects"
    }, null)
  }

  public async getFileByGuid(guid: string): Promise<any> {
    return await this.makeRequest("get", "/incoming-invoice/get-file-by-guid", {
      guid: guid
    }, null, "blob")
  }
}
