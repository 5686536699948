<template>
  <v-container v-if="!isLoading" class="pt-10">
    <v-row>
      <v-col>
        <v-row dense>
          <v-col>
            <h3>Non-Show-Project anlegen</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <NoShowProjectsItem
              :noShowProject="newNoShowProject"
              @add="addNoShowProject"
            ></NoShowProjectsItem>
          </v-col>
        </v-row>

        <v-row dense class="mt-10">
          <v-col>
            <h3>Non-Show-Projects</h3>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-if="noShowProjects.length > 0"
              v-model="nonArchivedNonShowProjectsFilter"
              @keyup="sortAndFilterNonArchived"
              label="Filter"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <template v-for="noShowProject in nonArchivedNoShowProjects">
              <NoShowProjectsItem
                :noShowProject="noShowProject"
                @remove="removeNoShowProject"
                @save="sortAndFilterNonArchived"
              ></NoShowProjectsItem>
            </template>

            <template v-if="nonArchivedNoShowProjects.length === 0">
              <v-row dense>
                <v-col>
                  <div>Es gibt noch keine Non-Show-Projects.</div>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>


        <v-row dense class="mt-10">
          <v-col>
            <h3>Archivierte Non-Show-Projects</h3>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-if="hasArchivedItems"
              v-model="archivedNonShowProjectsFilter"
              label="Filter"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <template v-for="noShowProject in archivedNoShowProjects">
              <NoShowProjectsItem
                :noShowProject="noShowProject"
                @remove="removeNoShowProject"
                @save="sortAndFilterArchived"
              ></NoShowProjectsItem>
            </template>

            <template v-if="archivedNoShowProjects.length === 0">
              Es wurden noch keine Non-Show-Projects archiviert.
            </template>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <div class="mt-6">
      <v-btn
        @click="backToSystem"
        class="ml-2"
      >
        Zurück zum System
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>

import { computed, onMounted, ref } from "vue"
import NoShowProjectsService        from "@/api/NoShowProjectsService"
import NoShowProjectsItem           from "@/components/NoShowProjectsItem.vue"
import NoShowProject                from "@/interfaces/NoShowProject"
import { useStore }                 from "vuex"

onMounted(async () => {
  await init()
})

// Initialize the loading state
const isLoading = ref(true)

// Create an instance of the service to interact with the backend
const noShowProjectService = ref(new NoShowProjectsService())

// Reactive reference to store all No-Show Projects
const noShowProjects = ref<NoShowProject[]>([])

// Reactive reference to store a new No-Show Project being added
const newNoShowProject = ref({} as NoShowProject)

// Access the Vuex store for state management
const store = useStore()

const hasArchivedItems = ref(false)

// Function to initialize data by fetching from the backend
const init = async () => {
  await noShowProjectService.value.getAllNoShowProjects().then(data => {
    if (data.success) {
      // Assign fetched data to the reactive reference
      noShowProjects.value = data.data

      sortAndFilterNonArchived()
      sortAndFilterArchived()

    } else {
      // Commit an error notification to Vuex store
      store.commit(
        "setErrorNotification",
        "Es ist ein Fehler beim Laden der No-Show-Projekte aufgetreten."
      )
    }
  })

  // Set loading state to false after data is fetched
  isLoading.value = false
}

// Function to navigate back to the system's main URL
const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL
}

// set filter for listing non-archived non-show-projects
const nonArchivedNonShowProjectsFilter = ref("")
const archivedNonShowProjectsFilter = ref("")

const nonArchivedNoShowProjects = ref([])
const archivedNoShowProjects = ref([])

let timer1: number
let timer2: number

// Computed property to filter and sort non-archived No-Show Projects
const sortAndFilterNonArchived = () => {
  clearTimeout(timer1)
  timer1 = setTimeout(() => {
    nonArchivedNoShowProjects.value = [...noShowProjects.value]
      .filter((item: NoShowProject) => {
        const searchTerm = nonArchivedNonShowProjectsFilter.value.trim().toLowerCase()

        // If the search term is empty, return only non-archived projects
        if (!searchTerm) {
          return !item.isArchived
        }

        // Split the search term into multiple words (terms) using spaces
        const terms = searchTerm.split(/\s+/)

        // Iterate over all attributes of the object and check for matches
        const hasHit = terms.every(term =>
          Object.values(item).some(value => {
            // Ensure value is searchable (string or number)
            if (typeof value === "string" || typeof value === "number") {
              return value.toString().toLowerCase().includes(term)
            }
            return false
          })
        )

        // Return true if all terms match at least one attribute, and the project is not archived
        return hasHit && !item.isArchived
      })
      .sort((a, b) => {
        // Sort by name in ascending order
        return a.name.localeCompare(b.name)
      })
  }, 100)
}

// Computed property to filter and sort archived No-Show Projects
const sortAndFilterArchived = () => {
  clearTimeout(timer2)
  timer2 = setTimeout(() => {

    hasArchivedItems.value = [...noShowProjects.value].filter((item: NoShowProject) => item.isArchived) > 0

    archivedNoShowProjects.value = [...noShowProjects.value]
      .filter((item: NoShowProject) => {
        const searchTerm = archivedNonShowProjectsFilter.value.trim().toLowerCase()

        // If the search term is empty, return only non-archived projects
        if (!searchTerm) {
          return item.isArchived
        }

        // Split the search term into multiple words (terms) using spaces
        const terms = searchTerm.split(/\s+/)

        // Iterate over all attributes of the object and check for matches
        const hasHit = terms.every(term =>
          Object.values(item).some(value => {
            // Ensure value is searchable (string or number)
            if (typeof value === "string" || typeof value === "number") {
              return value.toString().toLowerCase().includes(term)
            }
            return false
          })
        )

        // Return true if all terms match at least one attribute, and the project is not archived
        return hasHit && item.isArchived
      })
  }, 100)
}

// Function to remove a No-Show Project from the list
const removeNoShowProject = (data: { noShowProject: NoShowProject, type: string }) => {
  if (data.type === "deleted") {
    noShowProjects.value = noShowProjects.value.filter(item => item.id !== data.noShowProject.id)
  } else {
    data.noShowProject.isArchived = true
  }
}

// Function to add a new No-Show Project to the list
const addNoShowProject = (noShowProject: NoShowProject) => {
  noShowProjects.value.push(noShowProject)
  sortAndFilterNonArchived()
}

</script>
