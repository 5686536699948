<template>
  <v-container class="pt-14" v-if="!isLoading">
    <TourCashRegisterNavigationButtonLogout></TourCashRegisterNavigationButtonLogout>

    <div class="text-center">
      <h2>Tourkassen Übersicht für {{ loggedInPerson }}</h2>
      <template v-if="tours">
        <small>Bitte auf den Tourneetitel klicken um bestehende Belege anzuzeigen. Bitte auf den Kassenbetrag klicken um neue Belege zu erfassen.</small>
      </template>

      <template v-if="!tours">
        <div class="mt-10 pa-2 info-block">
          <v-icon icon="mdi-alert-outline"></v-icon>
          Ihnen sind noch keine Tourkassen zugeordnet.
        </div>
      </template>

      <template v-for="tour in tours">
        <div class="mt-4">

          <router-link
            class="button-box font-weight-bold"
            :to="{ name: 'tour-cash-register-overview', params: { id: tour.id } }"
          >
            {{ tour.name }}
          </router-link>

          <template v-for="tourCashRegister in tour.tourCashRegisters">
            <router-link
              class="button-box"
              :to="{ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegister.id } }"
            >
              {{ formatAmountAndCurrency(tourCashRegister.amount, tourCashRegister.currency) }}
            </router-link>
          </template>
        </div>
      </template>
    </div>
  </v-container>
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";
</style>

<script lang="ts">

import { onMounted, ref }                     from "vue"
import TourCashRegisterService                from "@/api/TourCashRegisterService"
import { useNumberFormatter }                 from "@/helpers/formatters"
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue"

export default {
  components: { TourCashRegisterNavigationButtonLogout },
  setup() {
    onMounted(async () => {
      await init()
    })

    const isLoading = ref(true)

    const tourCashRegisterService = ref(new TourCashRegisterService())

    const tours = ref()
    const loggedInPerson = ref()

    const { formatAmountAndCurrency } = useNumberFormatter()

    const init = async () => {
      let success = true

      await tourCashRegisterService.value.getLoggedInPerson().then(data => {
        if (data.success) {
          loggedInPerson.value = data.data.name
        } else {
          success = false
        }
      })

      if (success) {
        await tourCashRegisterService.value.getTourCashRegistersGrouped().then(data => {
          if (data.success) {
            tours.value = data.data
          } else {
            success = false
          }
        })
      }

      isLoading.value = false
    }

    return {
      isLoading,
      loggedInPerson,
      tours,
      formatAmountAndCurrency
    }
  }
}

</script>
