<template>
  <template v-if="!isLoading">
    <div class="d-flex flex-row section-header">
      <div class="flex-grow-1">
        Neuanlage
      </div>
    </div>

    <RequirementAttributeTypeItemItemEdit
      :editId="editId"
      :editMode="editId !== 0"
      :insertAfterItems="insertAfterItems()"
      :isEdited="editId === 0 || editId === -1"
      :requirementAttributeTypeItem="newRequirementAttributeTypeItem"
      :requirementAttributeType="requirementAttributeType"
      @refresh="refresh"
      @resetEdit="resetEdit"
      @setEdit="setEdit"
    ></RequirementAttributeTypeItemItemEdit>

    <draggable
      :list="allRequirementAttributeTypeItem"
      item-key="id"
      v-bind="dragOptions"
      @end="draggingEnd"
      @start="dragging = true"
    >
      <template #item="{ element }">
        <RequirementAttributeTypeItemItemEdit
          :editId="editId"
          :editMode="editId !== 0"
          :insertAfterItems="[]"
          :isEdited="editId === element.id"
          :requirementAttributeTypeItem="element"
          :requirementAttributeType="requirementAttributeType"
          @refresh="refresh"
          @resetEdit="resetEdit"
          @setEdit="setEdit"
        ></RequirementAttributeTypeItemItemEdit>
      </template>
    </draggable>

  </template>
</template>

<script lang="ts" setup>

import { onMounted, reactive, Ref, ref, watch } from "vue"
import RequirementService                       from "@/api/RequirementService"
import draggable                                from "vuedraggable"
import RequirementAttributeTypeItem             from "@/interfaces/RequirementAttributeTypeItem"
import RequirementAttributeTypeItemItemEdit     from "@/components/RequirementAttributeTypeItemItemEdit"
import RequirementAttributeType                 from "@/interfaces/RequirementAttributeType"

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())
const isLoading = ref(true)
const editId = ref()
const dragging = ref(false)

const newRequirementAttributeTypeItem = new reactive({} as RequirementAttributeTypeItem)
const allRequirementAttributeTypeItem: Ref<RequirementAttributeTypeItem[]> = ref([])

const props = defineProps<{
  requirementAttributeType: RequirementAttributeType
}>()

const emit = defineEmits(["setEditMode"])

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: false,
  scrollSensitivity: 200,
  handle: ".handle"
})

const init = async () => {
  newRequirementAttributeTypeItem.id = -1

  await refresh()
  editId.value = 0

  isLoading.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = async (val?) => {
  await requirementService.value.getAllAttributeTypeItem().then(data => {
    if (data.success) {
      allRequirementAttributeTypeItem.value = data.data.filter((a) => a.ratId == props.requirementAttributeType.id)
    }
  })
}

watch(() => editId.value,
  () => {
    emit("setEditMode", { editMode: (editId.value !== 0) })
  }
)

const draggingEnd = async () => {
  requirementService.value.resortAttributeTypeItem(allRequirementAttributeTypeItem.value).then(data => {
    if (data.success) {
      // use backwards response with collection of items / filter only current attribute-type-item
      allRequirementAttributeTypeItem.value = data.data.RequirementAttributeTypeItem.filter((a) => a.ratId == props.requirementAttributeType.id)
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allRequirementAttributeTypeItem.value.forEach(item => {
    arr.push({ newSortorder: (item.sortorder + 5), name: item.value })
  })

  return arr
}

</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
