<template>
  <v-form @submit.prevent="saveNoShowProject" ref="myForm" v-model="isValid" class="mb-3">
    <v-row dense class="border">
      <v-col cols="6" lg="3">

        <v-text-field
          v-model="noShowProject.name"
          label="Name *"
          :rules="requiredRule"
          :disabled="noShowProject.isArchived"
        ></v-text-field>
      </v-col>

      <v-col cols="6" lg="3">
        <v-textarea
          rows="1"
          auto-grow
          v-model="noShowProject.description"
          label="Beschreibung"
          maxlength="2000"
          :disabled="noShowProject.isArchived"
        ></v-textarea>
      </v-col>

      <v-col cols="6" lg="1" style="min-width: 150px">
        <v-text-field
          v-model="noShowProject.oracleCode"
          label="Oracle-ID *"
          :rules="requiredRule"
          :disabled="noShowProject.isInUse || noShowProject.isArchived"
        ></v-text-field>
      </v-col>

      <v-col cols="6" lg="1" style="min-width: 120px">
        <v-text-field
          v-model="noShowProject.department"
          label="Department *"
          :rules="requiredRule"
          :disabled="noShowProject.isInUse || noShowProject.isArchived"
        ></v-text-field>
      </v-col>

      <v-col cols="6" lg="1" style="min-width: 150px">
        <DebouncedNumberInput
          v-model="noShowProject.validYear"
          control-variant="stacked"
          label="Gültigkeitsjahr"
          density="default"
          :min="validYearMin"
          :max="validYearMax"
          :clearable="true"
          :persistent-counter="true"
          :disabled="noShowProject.isArchived"
        ></DebouncedNumberInput>

      </v-col>

      <v-col cols="6" lg="1" style="min-width: 150px">
        <DatepickerInput
          :data-model="noShowProject"
          field="validUntil"
          label="Wählbar bis einschl."
          :required="false"
          :clearable="true"
          return-object
          :disabled="noShowProject.isArchived"
          :class="( isExpired(noShowProject) ? 'date-expired' : '') + ' ' + ( noShowProject.isArchived ? 'dp--disabled' : '')"
          :hint="isExpired(noShowProject) ? 'Zieldatum bereits erreicht' : ''"
        ></DatepickerInput>
      </v-col>

      <v-col cols="12" lg="auto">
        <div class="d-flex">
          <SaveButton
            v-if="!noShowProject.isArchived"
            :disabled="isSaving || !isValid"
            @callback="saveNoShowProject"
          ></SaveButton>

          <DeleteButton
            v-if="!noShowProject.isArchived && noShowProject.id"
            :disabled="isSaving"
            :archive="noShowProject.isInUse"
            class="ml-2"
            @callback="deleteNoShowProject"
          ></DeleteButton>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref, watch} from "vue"
import NoShowProjectsService        from "@/api/NoShowProjectsService"
import NoShowProject                from "@/interfaces/NoShowProject"
import SaveButton                   from "@/components/SaveButton.vue"
import DeleteButton                 from "@/components/DeleteButton.vue"
import {useStore}                   from "vuex"
import DatepickerInput              from "@/components/DatepickerInput.vue"
import DebouncedNumberInput         from "@/components/DebouncedNumberInput.vue"
import dayjs from 'dayjs'
import * as DAYJS_LOCALE_DE from 'dayjs/locale/de';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale(DAYJS_LOCALE_DE);
dayjs.extend(CustomParseFormat);

const isLoading = ref(true)
const isSaving = ref(false)
const isValid = ref(true)

const myForm = ref(null)

const noShowProjectService = ref(new NoShowProjectsService())

const store = useStore()

const validYearMin = dayjs().year()
const validYearMax = dayjs().year() + 5

// Lifecycle hook to initialize data on component mount
onMounted(async () => {
  await init()
})

// Define props and emits
const props = defineProps<{
  noShowProject: NoShowProject
}>()

const emits = defineEmits(["remove", "add", "save"])

// Function to initialize the component state
const init = async () => {
  setTimeout(function () {
    watch(() => props.noShowProject,
      () => {
        store.commit("setDirty")
      },
      { deep: true }
    )
  }, 300)
  isLoading.value = false
}

// Validation rule for required fields
const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const dateToday = dayjs(new Date())

const isExpired = (noShowProject: NoShowProject) => {
  if (!noShowProject || !noShowProject.validUntil || noShowProject.isArchived) {
    return false
  }

  const validUntilDate = dayjs(noShowProject.validUntil, "DD.MM.YYYY", 'de', true).add(1, 'day')
  return validUntilDate.unix() < dateToday.unix()
}

// Function to save the No-Show Project
const saveNoShowProject = async () => {
  if (isValid.value) {
    isSaving.value = true

    await noShowProjectService.value.saveNoShowProject(props.noShowProject).then(async data => {
      if (!!data && data.success) {
        if (!props.noShowProject.id) {
          // Create new Non-Show-Project, add to list and then clear the "new" object
          const objNoReference = ref()
          objNoReference.value = JSON.parse(JSON.stringify(props.noShowProject))
          objNoReference.value.id = data.data.noShowProject.id

          await emits("add", objNoReference.value)

          await resetObject(props.noShowProject)
        }

        store.commit("unsetDirty")
        store.commit("setSuccessNotification", "Das Non-Show-Project wurde erfolgreich gespeichert.")

      } else {
        store.commit("setErrorNotification", "Das Non-Show-Project konnte nicht gespeichert werden.")
      }
    })

    emits("save")
    isSaving.value = false
  }
}

// Function to delete the No-Show Project
const deleteNoShowProject = async () => {
  isSaving.value = true

  await noShowProjectService.value.deleteNoShowProject(props.noShowProject).then(async data => {
    if (!!data && data.success) {
      await emits("remove", {
        noShowProject: props.noShowProject,
        type: data.data.noShowProject === null ? "deleted" : "archived"
      })

      store.commit("unsetDirty")
      store.commit("setSuccessNotification", "Das Non-Show-Project wurde erfolgreich gelöscht.")

    } else {
      // isSaving.value = false
      store.commit("setErrorNotification", "Das Non-Show-Project konnte nicht gelöscht werden.")
    }
  })

  isSaving.value = false
}

// Function to reset the object properties
function resetObject(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = null
    }
  }
}

</script>

<style>
.date-expired {

  .dp-field {
    border: 1px solid red;
  }

  .v-messages__hint {
    color: red;
    font-style: italic;
  }
}
</style>
