<template>
  <v-container v-if="!isLoading" class="pt-10">
    <div class="d-flex">
      <div>
        <h1>Oracle-ID Vergabe</h1>
      </div>
      <div style="margin-left: auto;">
        <TutorialButton :video-id="3"></TutorialButton>
      </div>
    </div>

    <div v-if="isSaved" class="text-center">
      <div class="text-success">Die Oracle-IDs wurden erfolgreich gesetzt.</div>
      <br/>
      <div>
        <div>
          <v-btn @click="reloadPage">Weitere Oracle-IDs vergeben</v-btn>
          &nbsp;
          <v-btn @click="backToSystem">Zurück zum System</v-btn>
        </div>
      </div>
    </div>
    <v-form @submit.prevent="saveOracleCodes" ref="myForm" v-model="isValid" v-else>

      <ConcertAddOracleCodesTourBlock :tours="tours" :selectedTour="selectedTour"></ConcertAddOracleCodesTourBlock>

      <ConcertAddOracleCodesConcertsBlock :selectedTour="selectedTour" :allDepartments="allDepartments"></ConcertAddOracleCodesConcertsBlock>

      <template v-if="selectedTour">
        <div>
          <v-btn @click="cancel">Abbrechen</v-btn>
          <v-btn @click="saveOracleCodes" class="ml-3">Speichern und Oracle-IDs jetzt anlegen *</v-btn>
        </div>
        <div class="mt-2 ml-2">
          <small>*) Info: Durch das Speichern werden die Konzerte automatisch bestätigt. Weiterhin werden den Konzerten automatisch die Standard-VVK-Systeme zugeordnet.</small>
        </div>
      </template>

    </v-form>

    <div v-if="tours.length === 0">
      Es sind keine Konzerte ohne Oracle-IDs vorhanden.
    </div>
  </v-container>
</template>

<script setup lang="ts">

import { useStore } from "vuex"
import { onMounted, Ref, ref, watch } from "vue"
import ConcertService from "@/api/concert/ConcertService"
import Tour from "@/interfaces/Tour"
import ConcertAddOracleCodesTourBlock from "@/components/ConcertAddOracleCodesTourBlock.vue"
import useEventsBus from "@/composables/eventBus"
import ConcertAddOracleCodesConcertsBlock from "@/components/ConcertAddOracleCodesConcertsBlock.vue"
import TutorialButton from "@/components/TutorialButton.vue"
import Department from "@/interfaces/Department"

onMounted(async () => {
  await concertService.value.getToursWithoutOracleCode().then(data => {
    tours.value = data.data ?? []
  })

  await concertService.value.getAllDepartments().then(data => {
    allDepartments.value = data.data ?? []
  })

  await init()
})

const { bus } = useEventsBus()

watch(() => bus.value.get("setSelectedTour"), (val) => {
  // destruct the parameters
  const [selectedTourBus] = val ?? null
  selectedTour.value = selectedTourBus.tour
})

const init = async () => {
  isLoading.value = false
}

const store = useStore()

const concertService = ref(new ConcertService())

const myForm = ref(null)
const isValid = ref(true)

const isSaved = ref(false)
const isSaving = ref(false)
const isLoading = ref(true)

const tours: Ref<Tour[]> = ref([])
const selectedTour: Ref<Tour | null> = ref(null)

const allDepartments: Ref<Department[]> = ref([])

const noResultsText = "Keine Ergebnisse"

const saveOracleCodes = async () => {
  await myForm.value.validate()

  if (!isValid.value) {
    isSaving.value = false
    store.commit("setErrorNotification", "Speichern nicht möglich. Bitte Felder überprüfen.")
  }

  if (isValid.value && !!selectedTour.value) {
    concertService.value.saveOracleCodesForTourAndSend(selectedTour.value).then(data => {
      if (!!data && data.success) {
        store.commit("unsetDirty")
        store.commit("setSuccessNotification", "Die Oracle-IDs wurden erfolgreich gespeichert.")
        isSaved.value = true
      } else {
        isSaving.value = false
        store.commit("setErrorNotification", "Die Oracle-IDs konnten nicht gespeichert werden.")
      }
    })
  }
}

const cancel = () => {
  selectedTour.value?.concerts.forEach(concert => {
    concert.oracleCode = null
  })
  selectedTour.value = null
}

const reloadPage = () => {
  window.location.reload()
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + "/"
}
</script>

<style>
.error-block {
  border: 1px solid rgb(176, 0, 32);
  color: rgb(176, 0, 32);
}

tbody.tours-overview tr.selected td {
  background-color: #eee;
}
</style>
