<script lang="ts" setup>

import { onMounted, ref }                from "vue"
import RequirementTemplateContainerBlock from "@/components/RequirementTemplateContainerBlock"
import RequirementAttributeTypeBlock     from "@/components/RequirementAttributeTypeBlock"
import RequirementService                from "@/api/RequirementService"

const emit = defineEmits(["refresh"])

const isLoading = ref(true)
const tab = ref()
const editMode = ref(false)

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())
const requirementAttributeTypes = ref({})

const init = async () => {

  // get all attribute types
  await requirementService.value.getAllAttributeType().then(data => {
    requirementAttributeTypes.value = data.data
  })

  isLoading.value = false
}

const refresh = async (val) => {
  await init()
}

const setEditMode = (val) => {
  editMode.value = val.editMode
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + ""
}

</script>

<template>
  <v-container v-if="!isLoading" class="pt-10">

    <div class="mb-5">
      <h1>Requirements Verwaltung (<a href="#" @click="backToSystem">zurück zum System</a>)</h1>
    </div>

    <v-tabs v-model="tab" bg-color="grey-lighten-2">
      <v-tab :tabindex="20001" value="templatecontainer" :disabled="tab !== 'templatecontainer' && editMode">
        Container Templates
        <v-icon
          v-if="tab === 'templatecontainer' && editMode"
          class="ml-2"
          icon="mdi-content-save"
          size="medium"
        ></v-icon>
      </v-tab>

      <v-tab :tabindex="20002" value="templateattribute" :disabled="tab !== 'templateattribute' && editMode">
        Attributstypen
        <v-icon
          v-if="tab === 'templateattribute' && editMode"
          class="ml-2"
          icon="mdi-content-save"
          size="medium"
        ></v-icon>
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="templatecontainer">
          <RequirementTemplateContainerBlock
            @setEditMode="setEditMode"
            :requirementAttributeTypes="requirementAttributeTypes"
          ></RequirementTemplateContainerBlock>
        </v-window-item>

        <v-window-item value="templateattribute">
          <RequirementAttributeTypeBlock
            @setEditMode="setEditMode"
            @refresh="refresh"
          ></RequirementAttributeTypeBlock>
        </v-window-item>
      </v-window>
    </v-card-text>

  </v-container>

</template>
