<template>

  <div class="dp-block">
    <div class="dp-field" :class="{'hasError': !!errorMessage }">
      <label class="dp-label">{{ label }}{{!!required ? ' *' : ''}}</label>

      <Datepicker
        v-model="dataModel[field]"
        locale="de"
        modelType="dd.MM.yyyy"
        :format-locale="de"
        format="dd.MM.yyyy"
        :enableTimePicker="false"
        :clearable="clearable"
        :rules="{requiredRule: required}"
        textInput
        autoApply
        autocomplete="off"
        :textInputOptions="{format: 'dd.MM.yyyy'}"
        :disabled="disabled"
        :teleport="true"
      ></Datepicker>
    </div>

    <div class="v-input__details">
      <div class="v-messages">
        <div class="v-messages__message d-flex" style="transform-origin: center top 0;">

          <div class="v-messages__errorMessage mr-3">
            {{ errorMessage }}
          </div>
          <div class="v-messages__hint" v-if="!!hint">
            {{ hint }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<style>
@import "@/styles/datepicker.css";
</style>

<style scoped>
.v-messages__hint {
  color: #666;
}
.v-messages__errorMessage {
  color: #B00020;
}
</style>

<script lang="ts">
import Datepicker from "@vuepic/vue-datepicker";
import { ref, watch } from "vue";
import { useNumberFormatter } from "@/helpers/formatters";
import {de} from "date-fns/locale";

export default {
  name: 'DatepickerInput',
  computed: {
    de() {
      return de
    }
  },
  components: { Datepicker },
  props: {
    options: Object,
    label: { type: String, default: 'Datum' },
    dataModel: Object,
    field: String,
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    hint: String | null,
    disabled: { type: Boolean, default: false },
  },
  emits: {

  },
  setup(props) {
    const { formatForCalendar } = useNumberFormatter()

    const errorMessage = ref()

    const formatDate = () => {
      return formatForCalendar(props.dataModel[props.field])
    }

    watch(
      () => props.dataModel[props.field], // Vue 2: props.value
      (value) => {
        // check for required error
        errorMessage.value = null
        if (!!props.required && !value) {
          errorMessage.value = 'Darf nicht leer sein'
        }
      },
      { deep: true }
    )

    const requiredRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    return { requiredRule, formatDate, errorMessage }
  },
}
</script>
