import RequirementTourPosition from "@/interfaces/RequirementTourPosition"

export function useRequirementsFunctions() {

    const filterRequirementTourPositions = (requirementTourPositions: RequirementTourPosition[]) => {
        return requirementTourPositions.filter(position => {
            let hasAllFieldSet = true
            position.requirementValues.forEach(value => {
                if (value.requirementAttribute.requirementAttributeType.datatype === "int") {
                    if (value.requirementAttribute.requirementAttributeType.systemKey === "concert") {
                        hasAllFieldSet = hasAllFieldSet && (value.valueConId !== null)
                    } else {
                        hasAllFieldSet = hasAllFieldSet && (value.valueInt !== null)
                    }
                }
                if (value.requirementAttribute.requirementAttributeType.datatype === "string" || value.requirementAttribute.requirementAttributeType.datatype === "string_long") {
                    //TODO: this needs a better solution than just checking for the name which is unreliable
                    if (value.requirementAttribute.name !== "Bemerkung") {
                        hasAllFieldSet = hasAllFieldSet && (value.valueString !== null && value.valueString !== "")
                    }
                }
                if (value.requirementAttribute.requirementAttributeType.datatype === "file") {
                    hasAllFieldSet = hasAllFieldSet && (value.requirementValueFiles.length > 0)
                }
                if (value.requirementAttribute.requirementAttributeType.datatype === "bool") {
                    hasAllFieldSet = hasAllFieldSet && (value.valueBool === true)
                }
                if (value.requirementAttribute.requirementAttributeType.datatype === "float" || value.requirementAttribute.requirementAttributeType.datatype === "price") {
                    hasAllFieldSet = hasAllFieldSet && (value.valueFloat !== null)
                }
                if (value.requirementAttribute.requirementAttributeType.datatype === "item") {
                    hasAllFieldSet = hasAllFieldSet && (value.valueRatiId !== null)
                }
            })
            return !hasAllFieldSet
        })
    }

    return {
        filterRequirementTourPositions
    }
}
