import BaseApiService               from "./BaseApiService"
import RequirementValue             from "@/interfaces/RequirementValue"
import RequirementValueFileItem     from "@/components/RequirementValueFileItem"
import RequirementTemplateContainer from "@/interfaces/RequirementTemplateContainer"
import RequirementAttributeType     from "@/interfaces/RequirementAttributeType"
import RequirementTemplateAttribute from "@/interfaces/RequirementTemplateAttribute"
import RequirementAttributeTypeItem from "@/interfaces/RequirementAttributeTypeItem"

export default class RequirementService extends BaseApiService {
  public async getAllRequirementContainers(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-requirement-containers", { expand: "tour" })
  }

  public async getAllRequirementContainersForTour(tourId: number): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-requirement-containers-for-tour", { tourId: tourId })
  }

  public async getRequirementPositionsForRequirementContainer(containerId: number): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-requirement-tour-positions-for-requirement-container", {
      containerId: containerId,
      expand: "requirementValues.concert,requirementValues.concert.department"
    })
  }

  public async getAllContainers(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-containers")
  }

  public async addNewPositionForContainer(containerId: number, currencyId: number | null): Promise<any> {
    return await this.makeRequest("get", "/requirement/add-new-position-for-container", {
      containerId: containerId,
      currencyId: currencyId
    })
  }

  public async selectRequirementContainersForTour(selectedContainers: [], tourId: number): Promise<any> {
    return await this.makeRequest("post", "/requirement/select-requirement-containers-for-tour", null, {
      selectedContainers: selectedContainers,
      tourId: tourId
    })
  }

  public async deletePosition(positionId: number): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-position", { positionId: positionId })
  }

  public async deleteFile(fileId: number): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-file", { id: fileId })
  }

  public async saveFile(requirementValueFileItem: RequirementValueFileItem): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-file", null, { requirementValueFileItem: requirementValueFileItem })
  }

  public async saveRequirementValue(requirementValue: RequirementValue): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-requirement-value", null, { requirementValue: requirementValue })
  }

  public async uploadFile(requirementValue: any, file: File): Promise<any> {
    return await this.makeRequest("post", "/requirement/upload-file", null, {
      requirementValue: requirementValue,
      file: file
    })
  }

  public async getAllConcertsForTour(tourId: number): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-concerts-for-tour", {
      tourId: tourId,
      expand: "department"
    })
  }

  public async getFileByGuid(guid: string, inline: boolean | null): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-file-by-guid", { guid: guid, inline: inline }, null, "blob")
  }

  public async resortTemplateContainer(requirementTemplateContainer: RequirementTemplateContainer[]) {
    return await this.makeRequest("post", "/requirement/resort-requirement-template-container", null, { requirementTemplateContainer: requirementTemplateContainer })
  }

  public async saveTemplateContainer(requirementTemplateContainer: RequirementTemplateContainer): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-requirement-template-container", null, { requirementTemplateContainer: requirementTemplateContainer })
  }

  public async saveTemplateAttribute(requirementTemplateAttribute: RequirementTemplateAttribute, requirementTemplateContainer?: RequirementTemplateContainer): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-requirement-template-attribute", null, { requirementTemplateAttribute: requirementTemplateAttribute, requirementTemplateContainer: requirementTemplateContainer })
  }

  public async saveAttributeType(requirementAttributeType: RequirementAttributeType): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-requirement-attribute-type", null, { requirementAttributeType: requirementAttributeType })
  }

  public async saveAttributeTypeItem(requirementAttributeTypeItem: RequirementAttributeTypeItem, requirementAttributeType: RequirementAttributeType): Promise<any> {
    return await this.makeRequest("post", "/requirement/save-requirement-attribute-type-item", null, { requirementAttributeTypeItem: requirementAttributeTypeItem, requirementAttributeType: requirementAttributeType })
  }

  public async deleteTemplateContainer(requirementTemplateContainer: RequirementTemplateContainer): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-requirement-template-container", { id: requirementTemplateContainer.id })
  }

  public async deleteTemplateAttribute(requirementTemplateAttribute: RequirementTemplateAttribute): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-requirement-template-attribute", { id: requirementTemplateAttribute.id })
  }

  public async deleteAttributeType(requirementAttributeType: RequirementAttributeType): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-requirement-attribute-type", { id: requirementAttributeType.id })
  }

  public async resortTemplateAttribute(requirementTemplateAttribute: RequirementTemplateAttribute[]) {
    return await this.makeRequest("post", "/requirement/resort-requirement-template-attribute", null, { requirementTemplateAttribute: requirementTemplateAttribute })
  }

  public async resortAttributeType(requirementAttributeType: RequirementAttributeType[]) {
    return await this.makeRequest("post", "/requirement/resort-requirement-attribute-type", null, { requirementTemplateAttribute: requirementAttributeType })
  }

  public async getAllTemplateAttributes(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-template-attributes")
  }

  public async getAllAttributeType(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-attribute-types")
  }

  public async deleteAttributeTypeItem(requirementAttributeTypeItem: RequirementAttributeTypeItem): Promise<any> {
    return await this.makeRequest("get", "/requirement/delete-requirement-attribute-type-item", { id: requirementAttributeTypeItem.id })
  }

  public async resortAttributeTypeItem(requirementAttributeTypeItem: RequirementAttributeTypeItem[]) {
    return await this.makeRequest("post", "/requirement/resort-requirement-attribute-type-item", null, { requirementAttributeTypeItem: requirementAttributeTypeItem })
  }

  public async getAllAttributeTypeItem(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-attribute-type-items")
  }

  public async getAllContainerRules(): Promise<any> {
    return await this.makeRequest("get", "/requirement/get-all-container-rules")
  }

}
