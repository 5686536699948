<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1 class="mb-4">Requirement-Übersicht (<a href="#" @click="backToSystem">Zurück zum System</a>)</h1>

    <template v-if="allRequirementTourContainers.length === 0">
      Es wurden noch keine Daten hinterlegt.
    </template>

    <template v-else>
      <RequirementSettings></RequirementSettings>

      <template v-for="requirementTourContainer in allRequirementTourContainers">
        <RequirementOverviewTourContainerBlock
          :requirementTourContainer="requirementTourContainer"
        ></RequirementOverviewTourContainerBlock>
      </template>
    </template>

  </v-container>
</template>

<script lang="ts" setup>

import { onMounted, Ref, ref }     from "vue"
import RequirementService                    from "@/api/RequirementService"
import RequirementTourContainer              from "@/interfaces/RequirementTourContainer"
import Tour                                  from "@/interfaces/Tour"
import RequirementOverviewTourContainerBlock from "@/components/RequirementOverviewTourContainerBlock.vue"
import RequirementSettings                   from "@/components/RequirementSettings.vue"

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())

const id = ref()
const isLoading = ref(true)

const allRequirementTourContainers: Ref<RequirementTourContainer[]> = ref([])
const tour: Ref<Tour> = ref({})

const init = async () => {
  await requirementService.value.getAllRequirementContainers().then(data => {
    if (data.success && data.data) {
      allRequirementTourContainers.value = data.data
    }
  })

  isLoading.value = false
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL
}
</script>

<style>
#app {
  max-width: inherit !important;
}

.v-container {
  max-width: inherit !important;
}
</style>
