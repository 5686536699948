<template>
  <v-btn :disabled="disabled"
         :size="size"
         :tabindex="tabindex"
         :color="color"
         @click="callback"
  >
    <template v-if="buttonText">
      <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin mr-2"></v-icon> {{ buttonText }}
    </template>
    <template v-else>
      <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
      <v-icon v-else icon="mdi-content-save"></v-icon>
    </template>

    <v-tooltip
      activator="parent"
      location="top"
      v-if="tooltip"
    >
      {{ tooltip }}
    </v-tooltip>
  </v-btn>
</template>

<script lang="ts" setup>

const emits = defineEmits(["callback"])
const props = defineProps({
  size: {
    type: String,
    default: "default"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  callBackId: {
    type: Number,
    default: null
  },
  tabindex: {
    type: Number,
    default: null
  },
  buttonText: {
    type: String,
    default: null
  },
  color: {
    type: String,
    default: "default"
  },
  tooltip: {
    type: String,
    default: "Speichern"
  }
})

const callback = () => {
  emits("callback", props.callBackId)
}
</script>
