<template>
  <div :class="requirementTemplateContainer.id > 0 ? 'section-item' : ''">
    <div
      v-if="!isLoading"
      :class="sectionClass()"
    >
      <v-form
        ref="myForm"
        v-model="isValid"
        @submit.prevent="saveTemplateContainer"
      >
        <div class="d-flex flex-row section-row" v-on:dblclick="() => { if(requirementTemplateContainer.id > 0) edit() }">
          <div
            v-if="requirementTemplateContainer.id > 0 && !editMode"
            class="section-column centered"
            style="width: 50px;"
          >
            <v-icon
              class="handle"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>

          <div class="flex-grow-1 section-column centered">
            <template v-if="isEdited || requirementTemplateContainer.id === 0 || requirementTemplateContainer.id === -1">
              <v-text-field
                v-model="requirementTemplateContainer.name"
                :disabled="!isEdited"
                :rules="requiredRule"
                :tabindex="(requirementTemplateContainer.sortorder ?? 0) * 10 + 1"
                label="Containername *"
                @keyup="checkIfNewIsChanged"
              ></v-text-field>
            </template>

            <template v-else>
              {{ requirementTemplateContainer.name }}
            </template>
          </div>

          <div class="flex-grow-1 section-column centered v-col-2">
            <template v-if="isEdited || requirementTemplateContainer.id === 0 || requirementTemplateContainer.id === -1">
              <v-select
                v-model="requirementTemplateContainer.rulId"
                :disabled="!isEdited"
                :items="props.rules"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="(requirementTemplateContainer.sortorder ?? 0) * 10 + 2"
                item-title="name"
                item-value="id"
                label="Berechtigung"
                clearable
              ></v-select>
            </template>

            <template v-else>
              {{ requirementTemplateContainer?.rule?.name }}
            </template>
          </div>

          <div class="section-column centered v-col-3 v-col-xl-2">
            <template v-if="requirementTemplateContainer.id < 1">
              <v-select
                v-model="requirementTemplateContainer.insertAfter"
                :disabled="!isEdited"
                :items="getItemsForInsertAfter()"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 5"
                clearable
                item-title="name"
                item-value="newSortorder"
                label="Einfügen nach"
                return-object
              ></v-select>
            </template>
          </div>

          <div class="section-column v-col-3 v-col-lg-4">
            <template v-if="isEdited">
              <div class="d-flex justify-end">
                <SaveButton
                  :disabled="isSaving"
                  :is-saving="isSaving"
                  :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 7"
                  @callback="saveTemplateContainer"
                ></SaveButton>

                <CancelButton
                  :disabled="isSaving"
                  :is-saving="isSaving"
                  :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 8"
                  @callback="reset"
                ></CancelButton>
              </div>
            </template>

            <template v-else-if="!editMode">
              <div class="d-flex justify-end">

                <v-btn
                  @click="openListDialog(requirementTemplateContainer)"
                  :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 6 "
                >
                  <v-icon icon="mdi-format-list-bulleted"></v-icon>

                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    Listeneinträge bearbeiten
                  </v-tooltip>
                </v-btn>

                <EditButton
                  :disabled="isSaving"
                  :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 7"
                  @callback="edit"
                ></EditButton>

                <DeleteButton
                  :archive="false"
                  :disabled="isSaving"
                  :is-saving="isSaving"
                  :tabindex="((requirementTemplateContainer.sortorder ?? 0) * 10) + 8"
                  @callback="deleteRequirementTemplateContainer"
                ></DeleteButton>
              </div>
            </template>
          </div>
        </div>

      </v-form>
    </div>
  </div>

  <v-dialog
    v-model="dialog"
  >
    <v-card
      prepend-icon="mdi-format-list-bulleted"
      :title="dialogTitle"
    >
      <div style="padding: 1rem">
        <RequirementTemplateAttributeBlock
          :requirementTemplateContainer="dialogItem"
          :requirementAttributeTypes="requirementAttributeTypes"
        ></RequirementTemplateAttributeBlock>
      </div>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Schliessen"
          @click="dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>

</template>

<script lang="ts" setup>

import { onMounted, ref }                from "vue"
import { useString }                     from "@/helpers/string"
import { VForm }                         from "vuetify/components"
import DeleteButton                      from "@/components/DeleteButton.vue"
import SaveButton                        from "@/components/SaveButton.vue"
import CancelButton                      from "@/components/CancelButton.vue"
import EditButton                        from "@/components/EditButton.vue"
import RequirementService                from "@/api/RequirementService"
import RequirementTemplateContainer      from "@/interfaces/RequirementTemplateContainer"
import RequirementTemplateAttributeBlock from "@/components/RequirementTemplateAttributeBlock.vue"
import RequirementAttributeType          from "@/interfaces/RequirementAttributeType"
import Rule                              from "@/interfaces/Rule"

const dialog = ref(false)
const dialogItem = ref({})
const dialogTitle = ref("")

const openListDialog = (templateContainer: RequirementTemplateContainer) => {
  dialog.value = true
  dialogItem.value = templateContainer
  dialogTitle.value = "Container: [" + templateContainer.name + "]"
}

onMounted(async () => {
  await init()
})

const props = defineProps<{
  requirementTemplateContainer: RequirementTemplateContainer,
  requirementAttributeTypes: RequirementAttributeType[]
  rules: Rule[],
  editId: number,
  isEdited: boolean,
  editMode: boolean,
  insertAfterItems: []
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh"])

const isValid = ref()
const isLoading = ref(true)
const isSaving = ref(false)
const tab = ref()

const requirementService = ref(new RequirementService())
const { nl2br } = useString()

const id = ref()
const objNoReference = ref()
const myForm = ref({} as VForm)

const init = async () => {
  isLoading.value = false
  id.value = props.requirementTemplateContainer.id

  objNoReference.value = JSON.parse(JSON.stringify(props.requirementTemplateContainer))
}

const reset = () => {
  props.requirementTemplateContainer.name = objNoReference.value.name
  props.requirementTemplateContainer.insertAfter = objNoReference.value.insertAfter

  emit("resetEdit")
  resetValidation()
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.requirementTemplateContainer))
  emit("setEdit", { id: id.value })
}

const saveTemplateContainer = async () => {
  isSaving.value = true
  await validate()

  if (isValid.value) {
    await requirementService.value.saveTemplateContainer(props.requirementTemplateContainer).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allRequirementTemplateContainer = data.data.requirementTemplateContainer

        emit("refresh", { allRequirementTemplateContainer })
        reset()
      }
    })
  }

  isSaving.value = false
}

const deleteRequirementTemplateContainer = async () => {
  isSaving.value = true

  await requirementService.value.deleteTemplateContainer(props.requirementTemplateContainer).then(data => {
    if (!!data && !!data.data && data.data.success) {
      let list = data.data.requirementTemplateContainers
      emit("refresh", { list })
    }
  })

  isSaving.value = false
}

const checkIfNewIsChanged = () => {
  if (props.requirementTemplateContainer.id < 1) {

    // default
    props.requirementTemplateContainer.id = 0

    // if name is given - flag this object as new
    if (props.requirementTemplateContainer.name) {
      props.requirementTemplateContainer.id = -1
    }

    id.value = props.requirementTemplateContainer.id
    emit("setEdit", { id: id.value })
  }
}

const sectionClass = () => {
  if (!props.isEdited && props.editMode) {
    return "section-locked"
  }
  if (props.requirementTemplateContainer.id > 0 && !props.editMode) {
    return "section-block"
  }
  if (props.isEdited && props.editMode) {
    return "section-editing"
  }

  return ""
}

const getItemsForInsertAfter = () => {
  return props.insertAfterItems
}
const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])
</script>


<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
</style>
