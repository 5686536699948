import { reactive, ref } from "vue"
import IncomingInvoice   from "@/interfaces/IncomingInvoice";
import InvoiceGroup from "@/interfaces/InvoiceGroup";

const defaultNotificationData = { message: null, timeout: 3000, color: 'green' };

export default {
  state: {
    globalNotification: defaultNotificationData,
    incomingInvoice: reactive({} as IncomingInvoice),
    availableInvoiceGroups: reactive({} as InvoiceGroup[]),
    requirementsShowOnlyMissing: ref(false)
  },
  getters: {
    getGlobalNotification(state) {
      return state.globalNotification;
    },
    getRequirementsShowOnlyMissing(state) {
      return state.requirementsShowOnlyMissing
    }
  },
  mutations: {
    setSuccessNotification(state, message) {
      state.globalNotification = {
        message: message,
        timeout: 3000,
        color: 'green',
      };
    },
    setErrorNotification(state, message) {
      state.globalNotification = {
        message: message,
        timeout: -1,
        color: 'red',
      };
    },
    setRequirementsShowOnlyMissing(state, val) {
      state.requirementsShowOnlyMissing = val
    },
    clearNotification(state) {
      state.globalNotification = defaultNotificationData;
    }
  },
}
