<template>
  <v-btn @click="getContainers">
    Container auswählen
  </v-btn>

  <v-overlay
      v-model="containerOverlay"
      class="align-center justify-center"
  >
    <v-card style="width: 1200px">
      <v-card-item>
        <v-card-title>
          Container-Auswahl
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row v-if="!isLoading" dense>
          <v-col
              v-for="item in containers"
              sm="4"
          >
            <v-checkbox
                :label="item.name"
                v-model="item.isSelected"
                :disabled="item.hasPositions || !item.hasRights"
                density="compact"
                :hide-details="true"
            ></v-checkbox>
            <v-tooltip
              v-if="!item.hasRights"
              activator="parent"
              location="top"
            >
              Keine Berechtigung für diesen Container vorhanden
            </v-tooltip>
          </v-col>
        </v-row>

        <div class="align-center justify-center text-center" v-else>
          <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
            color="success"
            @click="saveSelectedRequirementContainersForTour"
        >Übernehmen
        </v-btn>

        <v-btn
            class="ml-2"
            @click="containerOverlay = false"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script lang="ts" setup>

import { onMounted, ref } from "vue"
import RequirementService from "@/api/RequirementService"
import { useRoute }       from "vue-router"
import { useStore }       from "vuex"
import useEventsBus       from "@/composables/eventBus"

onMounted(async () => {
  await init()
})

const route = useRoute()
const store = useStore()
const { emit } = useEventsBus()

const requirementService = ref(new RequirementService())

const containerOverlay = ref(false)
const isLoading = ref(true)

const containers = ref([])
const selectedContainers = ref([])

const id = ref()

const init = async () => {
  id.value = parseInt(route.params.id.toString())
}

const getContainers = async () => {
  containerOverlay.value = true

  console.log("running init")

  await requirementService.value.getAllRequirementContainersForTour(id.value).then(data => {
    if (data.success && data.data) {
      console.log("getting data (selected) done")
      selectedContainers.value = data.data
    }
  })

  await requirementService.value.getAllContainers().then(data => {
    if (data.success && data.data) {
      console.log("getting data (all) done")
      containers.value = data.data
      isLoading.value = false
    }
  })

  containers.value.forEach((item) => {
    item.isSelected = checkIfSelected(item)
    item.hasPositions = checkIfHasPositions(item)
  })
}

const saveSelectedRequirementContainersForTour = async () => {
  await requirementService.value.selectRequirementContainersForTour(containers.value, id.value).then(data => {
    if (data.success && data.data.success) {
      emit("refreshRequirements")
      store.commit("setSuccessNotification", "Requirement-Container wurden erfolgreich übernommen.")
      containerOverlay.value = false
    } else {
      store.commit("setErrorNotification", "Requirement-Container konnten nicht übernommen werden.")
    }
  })
}

const checkIfSelected = (container) => {
  return selectedContainers.value.filter((item) => {
    return item.rtecId === container.id
  }).length > 0
}

const checkIfHasPositions = (container) => {
  return selectedContainers.value.filter((item) => {
    return item.rtecId === container.id && item.hasPositions
  }).length > 0
}

</script>
