<template>
  <v-card class="mb-4">
    <v-card-item>
      <v-card-title>
        Einstellungen
      </v-card-title>
    </v-card-item>

    <v-card-text>
      <v-switch
          label="Nur fehlende anzeigen"
          color="#D9112D"
          v-model="showOnlyMissing"
          hide-details="true"
          inline
      />

      <RequirementContainerSelector v-if="showContainerSelect"></RequirementContainerSelector>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>

import { ref, watch } from "vue"
import { useStore }   from "vuex"
import RequirementContainerSelector from "@/components/RequirementContainerSelector.vue"

const store = useStore()

const showOnlyMissing = ref(false)

const props = withDefaults(defineProps<{
  showContainerSelect: boolean
}>(), {
  showContainerSelect: false
})

watch(() => showOnlyMissing.value, () => {
  store.commit("setRequirementsShowOnlyMissing", showOnlyMissing.value)
})

</script>
