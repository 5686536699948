<template>
  <div class="d-flex">
    <div class="no-overflow flex-grow-1">
      <a @click="downloadFile" style="cursor: pointer;">
        <v-tooltip
          activator="parent"
          location="top"
        >
          Herunterladen: {{ requirementValueFile.originalName }}
        </v-tooltip>
        {{ requirementValueFile.originalName }}
      </a>
    </div>
    <div class="text-right d-flex ml-4 align-content-center" v-if="editMode">

      <v-btn
        size="x-small"
        v-if="requirementTourContainer.canBeEdited"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
        <v-overlay
          v-model="overlay"
          location-strategy="connected"
          scroll-strategy="block"
          activator="parent"
          :close-on-content-click="false"
        >
          <div v-if="showDeleteConfirm">

            <v-card
              prepend-icon="mdi-delete"
              title="Datei löschen"
            >
              <v-card-text>
                Wirklich löschen?
              </v-card-text>

              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                  size="small"
                  variant="outlined"
                  @click.native="deleteFile"
                >
                  Ok
                </v-btn>
                <v-btn
                  size="small"
                  variant="outlined"
                  @click.native="showDeleteConfirm = false"
                >
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>

          </div>
          <div v-else>

            <v-card
              prepend-icon="mdi-pencil"
              title="Dateinamen ändern"
            >
              <v-card-text>

                <v-row dense class="mt-1">
                  <v-col
                    cols="12"
                  >
                    <v-form
                      ref="myField"
                      v-model="isValid"
                    >
                      <v-text-field
                        label="Dateiname"
                        required
                        :rules="requiredRule"
                        v-model="displayName"
                        @change="saveFile"
                        append-inner-icon="mdi-file-png-box"
                      >

                        <template v-slot:append-inner>
                          {{ extensionName }}
                        </template>

                      </v-text-field>
                    </v-form>

                    <div
                      class="text-grey mt-0 mb-2"
                    >
                      {{ requirementValueFile.lastChangeText ?? "-" }}
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="v-btn-group mt-2 justify-end"
                  >
                    <DeleteButton
                      @click="showDeleteConfirm = true"
                      v-if="requirementTourContainer.canBeEdited"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </DeleteButton>

                    <v-btn
                      class=""
                      @click="overlay = false"
                    >
                      Schließen
                    </v-btn>

                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>

          </div>

        </v-overlay>
      </v-btn>

      <div
        v-if="!requirementTourContainer.canBeEdited"
        class="ml-2"
      >
        <v-icon>
          mdi-history
        </v-icon>

        <v-tooltip
          activator="parent"
          location="top"
        >
          {{ requirementValueFile.lastChangeText ?? "-" }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue"
import RequirementValueFile   from "@/interfaces/RequirementValueFile"
import DeleteButton           from "@/components/DeleteButton.vue"
import RequirementService     from "@/api/RequirementService"

import RequirementTourContainer from "@/interfaces/RequirementTourContainer"
import {useStore}               from "vuex"
import Confirm                  from "@/components/Confirm.vue"

const store = useStore()

const showDeleteConfirm = ref(false)

const requirementService = ref(new RequirementService())

const emits = defineEmits(["deleteFile"])
const props = defineProps<{
  requirementTourContainer: RequirementTourContainer;
  requirementValueFile: RequirementValueFile,
  editMode: boolean
}>()

const myField = ref(null)
const isValid = ref(true)

const extensionName = ref("")
extensionName.value = props.requirementValueFile.originalName.substring(props.requirementValueFile.originalName.lastIndexOf("."))

const overlay = ref(false)

const resetState = () => {
  showDeleteConfirm.value = false
}

watch(overlay, (newValue, oldValue) => {
  if (!newValue) {
    resetState() // call resetState() function if overlay is closed
  }
})

const downloadFile = async () => {
  requirementService.value.getFileByGuid(props.requirementValueFile.guid, false).then(data => {
    if (data.success) {
      const url = URL.createObjectURL(data.data)
      const link = document.createElement("a")
      link.href = url
      link.download = props.requirementValueFile.originalName
      link.click()
      URL.revokeObjectURL(url)
    }
  })
}

const deleteFile = async () => {
  emits("deleteFile", props.requirementValueFile.id)
  resetState()
}

const saveFile = async () => {
  myField.value.validate()

  if (isValid.value) {
    requirementService.value.saveFile(props.requirementValueFile).then(data => {
      if (data.data.success) {
        props.requirementValueFile.originalName = data.data.requirementValueFile.originalName
        store.commit("setSuccessNotification", "Die Datei wurde erfolgreich gespeichert.")
      }
    })
  }
}

// Computed Property for display filename
const displayName = computed({
  get: () => {
    const originalName = props.requirementValueFile.originalName
    // remove filextension (all after last dot)
    return originalName.substring(0, originalName.lastIndexOf(".")) || ""
  },
  set: (value: string) => {
    // get current extension
    const extension = props.requirementValueFile.originalName.substring(
      props.requirementValueFile.originalName.lastIndexOf(".")
    )
    // set new value with saved extension
    props.requirementValueFile.originalName = value + extension
  }
})

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein",
  v => v.length > 2 || "Es sollten min. 3 Zeichen enthalten sein",
  v => /^[^<>:"/\\|?*\n\r]+$/.test(v) || "Darf keine unerlaubten Zeichen wie \\ / : * ? \" < > | enthalten"
])

</script>

<style scoped>
.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
