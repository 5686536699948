<template>


  <div :class="requirementTemplateAttribute.id > 0 ? 'section-item' : ''">
    <div
      v-if="!isLoading"
      :class="sectionClass()"
    >
      <v-form
        dense
        ref="myForm"
        v-model="isValid"
        @submit.prevent="saveTemplateAttribute"
      >
        <div class="d-flex flex-row section-row" v-on:dblclick="() => { if(requirementTemplateAttribute.id > 0) edit() }">
          <div
            v-if="requirementTemplateAttribute.id > 0 && !editMode"
            class="section-column centered"
            style="width: 50px;"
          >
            <v-icon
              class="handle"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>

          <div class="flex-grow-1 section-column centered v-col-4">
            <template v-if="isEdited || requirementTemplateAttribute.id === 0 || requirementTemplateAttribute.id === -1">
              <v-text-field
                v-model="requirementTemplateAttribute.name"
                :disabled="!isEdited"
                :rules="requiredRule"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 1"
                label="Name *"
                @keyup="checkIfNewIsChanged"
              ></v-text-field>
            </template>
            <template v-else>
              {{ requirementTemplateAttribute.name }}
            </template>
          </div>

          <div class="flex-grow-1 section-column centered v-col-2">
            <template v-if="isEdited || requirementTemplateAttribute.id === 0 || requirementTemplateAttribute.id === -1">
              <v-select
                v-model="requirementTemplateAttribute.ratId"
                :disabled="!isEdited"
                :items="props.requirementAttributeTypes"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 2"
                item-title="description"
                item-value="id"
                label="Datentyp *"
                :rules="requiredRule"
                return-object
              ></v-select>
            </template>
            <template v-else>
              {{ requirementTemplateAttribute.requirementAttributeType?.description }}
            </template>
          </div>

          <div class="" style="width: 150px">
            <template v-if="isEdited || requirementTemplateAttribute.id === 0 || requirementTemplateAttribute.id === -1">
              <v-checkbox
                :disabled="!isEdited"
                v-model="requirementTemplateAttribute.flgRequired"
                hide-details="false"
                label="Relevant"
                :true-value=1
                :false-value=0
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 3"
              ></v-checkbox>
            </template>
            <template v-else>
              <v-checkbox
                v-model="requirementTemplateAttribute.flgRequired" hide-details="true" :disabled="true" :true-value=1
                :false-value=0
              ></v-checkbox>
            </template>
          </div>

          <div class="section-column centered" style="width: 250px">
            <template v-if="requirementTemplateAttribute.id < 1">
              <v-select
                v-model="requirementTemplateAttribute.insertAfter"
                :disabled="!isEdited"
                :items="getItemsForInsertAfter()"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 4"
                clearable
                item-title="name"
                item-value="newSortorder"
                label="Einfügen nach"
                return-object
              ></v-select>
            </template>
          </div>

          <div class="section-column" style="width: 210px">
            <div class="d-flex justify-end" v-if="isEdited">
              <SaveButton
                :disabled="isSaving"
                :is-saving="isSaving"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 7"
                @callback="saveTemplateAttribute"
              ></SaveButton>

              <CancelButton
                :disabled="isSaving"
                :is-saving="isSaving"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 8"
                @callback="reset"
              ></CancelButton>
            </div>

            <div class="d-flex justify-end" v-else>
              <EditButton
                :disabled="isSaving"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 7"
                @callback="edit"
              ></EditButton>

              <DeleteButton
                :archive="false"
                :disabled="isSaving"
                :is-saving="isSaving"
                :tabindex="((requirementTemplateAttribute?.sortorder ?? 0) * 10) + 8"
                @callback="deleterequirementTemplateAttribute"
              ></DeleteButton>
            </div>
          </div>
        </div>

      </v-form>
    </div>
  </div>

</template>

<script lang="ts" setup>

import { onMounted, ref }           from "vue"
import { useString }                from "@/helpers/string"
import { VForm }                    from "vuetify/components"
import DeleteButton                 from "@/components/DeleteButton"
import SaveButton                   from "@/components/SaveButton"
import CancelButton                 from "@/components/CancelButton"
import EditButton                   from "@/components/EditButton"
import RequirementService           from "@/api/RequirementService"
import RequirementAttributeType     from "@/interfaces/RequirementAttributeType"
import RequirementTemplateAttribute from "@/interfaces/RequirementTemplateAttribute"
import RequirementTemplateContainer from "@/interfaces/RequirementTemplateContainer"

onMounted(async () => {
  await init()
})

const props = defineProps<{
  requirementTemplateAttribute: RequirementTemplateAttribute,
  requirementTemplateContainer?: RequirementTemplateContainer,
  requirementAttributeTypes: RequirementAttributeType[],
  editId: number,
  isEdited: boolean,
  editMode: boolean,
  insertAfterItems: []
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh"])

const isValid = ref()
const isLoading = ref(true)
const isSaving = ref(false)
const tab = ref()

const requirementService = ref(new RequirementService())
const { nl2br } = useString()

const id = ref()
const objNoReference = ref()
const myForm = ref({} as VForm)

const init = async () => {
  isLoading.value = false
  id.value = props.requirementTemplateAttribute.id

  // save org state
  objNoReference.value = JSON.parse(JSON.stringify(props.requirementTemplateAttribute))
}

const reset = () => {
  // restore org. values
  props.requirementTemplateAttribute.name = objNoReference.value.name
  props.requirementTemplateAttribute.ratId = objNoReference.value.ratId
  props.requirementTemplateAttribute.flgRequired = objNoReference.value.flgRequired
  props.requirementTemplateAttribute.insertAfter = objNoReference.value.insertAfter

  emit("resetEdit")
  resetValidation()
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.requirementTemplateAttribute))
  emit("setEdit", { id: id.value })
}

const saveTemplateAttribute = async () => {
  isSaving.value = true
  await validate()

  if (isValid.value) {
    await requirementService.value.saveTemplateAttribute(props.requirementTemplateAttribute, props.requirementTemplateContainer).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allrequirementTemplateAttribute = data.data.requirementTemplateAttribute

        emit("refresh", { allrequirementTemplateAttribute })
        reset()
      }
    })
  }

  isSaving.value = false
}

const deleterequirementTemplateAttribute = async () => {
  isSaving.value = true

  await requirementService.value.deleteTemplateAttribute(props.requirementTemplateAttribute).then(data => {
    if (!!data && !!data.data && data.data.success) {
      let list = data.data.requirementTemplateAttributes
      emit("refresh", { list })
    }
  })

  isSaving.value = false
}

const checkIfNewIsChanged = () => {
  if (props.requirementTemplateAttribute.id < 1) {

    // default
    props.requirementTemplateAttribute.id = 0

    // if name is given - flag this object as new
    if (props.requirementTemplateAttribute.name) {
      props.requirementTemplateAttribute.id = -1
    }

    id.value = props.requirementTemplateAttribute.id
    emit("setEdit", { id: id.value })
  }
}

const sectionClass = () => {
  if (!props.isEdited && props.editMode) {
    return "section-locked"
  }
  if (props.requirementTemplateAttribute.id > 0 && !props.editMode) {
    return "section-block"
  }
  if (props.isEdited && props.editMode) {
    return "section-editing"
  }

  return ""
}

const getItemsForInsertAfter = () => {
  return props.insertAfterItems
}
const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])


</script>


<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
</style>
