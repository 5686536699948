<template>
  <template v-if="selectedTour">
    <v-table class="mt-3">
      <thead>
      <tr>
        <th></th>
        <th>Tag</th>
        <th>Datum</th>
        <th>Beginn</th>
        <th>Ort</th>
        <th>Location</th>
        <th>Kap</th>
        <th>Art</th>
        <th>Department</th>
        <th>Co-Promoter</th>
        <th>SP</th>
        <th>Flat-Deal</th>
        <th>Oracle-ID</th>
        <th>Projektname</th>
        <th>Report Month / Year</th>
      </tr>
      </thead>

      <tbody>
      <template v-for="concert in selectedTour?.concerts">
        <ConcertAddOracleCodesConcertsItem
          :concert="concert"
          :allDepartments="allDepartments"
          @setProjectnameForAll="setProjectnameForAll"
          @setReportMonthAndYearForAll="setReportMonthAndYearForAll"
        ></ConcertAddOracleCodesConcertsItem>
      </template>
      </tbody>
    </v-table>
  </template>
</template>

<script setup lang="ts">
import Tour                              from "@/interfaces/Tour"
import ConcertAddOracleCodesConcertsItem from "@/components/ConcertAddOracleCodesConcertsItem.vue"

const props = defineProps<{
  selectedTour: Tour | null,
  allDepartments: any
}>()

const setProjectnameForAll = (concertItem) => {
  props.selectedTour?.concerts.forEach((item) => {
    if (item.oracleCode === concertItem.oracleCode) {
      item.projectName = concertItem.projectName
    }
  })
}

const setReportMonthAndYearForAll = (concertItem) => {
  props.selectedTour?.concerts.forEach((item) => {
    if (item.oracleCode === concertItem.oracleCode) {
      item.reportMonth = concertItem.reportMonth
      item.reportYear = concertItem.reportYear
    }
  })
}
</script>
