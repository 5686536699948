import TourCashRegisterIndexPage from '@/components/TourCashRegisterIndexPage.vue';
import TourCashRegisterOverviewPage from '@/components/TourCashRegisterOverviewPage.vue';
import TourCashRegisterReceiptCreatePage from '@/components/TourCashRegisterReceiptCreatePage.vue';
import TourCashRegisterReceiptEditPage from '@/components/TourCashRegisterReceiptEditPage.vue';
import TourCashRegisterReceiptIndexPage from '@/components/TourCashRegisterReceiptIndexPage.vue';
import WatchdogPage from '@/components/WatchdogPage.vue';
import {createRouter, createWebHistory} from 'vue-router';
import IncomingInvoicePage from '../components/IncomingInvoicePage.vue';
import TourCashRegisterReceiptOverviewPage from '@/components/TourCashRegisterReceiptOverviewPage.vue';
import ConcertCreatePage from '@/components/ConcertCreatePage.vue';
import ConcertAddOracleCodesPage from '@/components/ConcertAddOracleCodesPage.vue';
import VenueSpecsPage from '@/components/VenueSpecsPage.vue';
import VenueSpecsAdminPage from '@/components/VenueSpecsAdminPage.vue';
import WikiAdminPage from '@/components/WikiAdminPage.vue';
import WikiSubCategoriesPage from "@/components/WikiSubCategoriesPage.vue";
import WikiContentPage from "@/components/WikiContentPage.vue";
import WikiViewPage from '@/components/WikiViewPage.vue';
import RequirementPage from "@/components/RequirementPage.vue";
import RequirementAdminPage from "@/components/RequirementAdminPage.vue";
import RequirementOverviewPage from '@/components/RequirementOverviewPage.vue';
import NoShowProjectsPage from '@/components/NoShowProjectsPage.vue';


const routes = [
  {
    path: '/incoming-invoice',
    component: IncomingInvoicePage,
    name: 'incoming-invoice'
  },
  {
    path: '/tour-cash-register',
    component: TourCashRegisterIndexPage,
    name: 'tour-cash-register'
  },
  {
    path: '/tour-cash-register/receipt-index/:id',
    component: TourCashRegisterReceiptIndexPage,
    name: 'tour-cash-register-receipt-index'
  },
  {
    path: '/tour-cash-register/receipt-create/:type/:id',
    component: TourCashRegisterReceiptCreatePage,
    name: 'tour-cash-register-receipt-create'
  },
  {
    path: '/tour-cash-register/receipt-edit/:type/:id',
    component: TourCashRegisterReceiptEditPage,
    name: 'tour-cash-register-receipt-edit'
  },
  {
    path: '/tour-cash-register/overview/:id',
    component: TourCashRegisterOverviewPage,
    name: 'tour-cash-register-overview'
  },
  {
    path: '/tour-cash-register/receipt-overview/:id',
    component: TourCashRegisterReceiptOverviewPage,
    name: 'tour-cash-register-receipt-overview'
  },
  {
    path: '/concert-create',
    component: ConcertCreatePage,
    name: 'concert-create'
  },
  {
    path: '/concert-create/:id',
    component: ConcertCreatePage,
    name: 'concert-create-with-tour'
  },
  {
    path: '/concert-add-oracle-codes',
    component: ConcertAddOracleCodesPage,
    name: 'concert-add-oracle-codes'
  },
  {
    path: '/requirements-overview/',
    component: RequirementOverviewPage,
    name: 'requirements-overview'
  },
  {
    path: '/requirements-admin/',
    component: RequirementAdminPage,
    name: 'requirements-admin'
  },
  {
    path: '/requirements/:id',
    component: RequirementPage,
    name: 'requirements'
  },
  {
    path: '/venue-specs/:id',
    component: VenueSpecsPage,
    name: 'venue-specs'
  },
  {
    path: '/venue-specs-admin',
    component: VenueSpecsAdminPage,
    name: 'venue-specs-admin'
  },
  {
    path: '/wiki-admin',
    component: WikiAdminPage,
    name: 'wiki-admin'
  },
  {
    path: '/wiki-sub-categories/:id',
    component: WikiSubCategoriesPage,
    name: 'wiki-sub-categories'
  },
  {
    path: '/wiki-content/:id',
    component: WikiContentPage,
    name: 'wiki-content'
  },
  {
    path: '/wiki-view/:id',
    component: WikiViewPage,
    name: 'wiki-overview'
  },
  {
    path: '/no-show-projects',
    component: NoShowProjectsPage,
    name: 'no-show-projects-overview'
  },
  {
    path: '/watchdog',
    component: WatchdogPage,
    name: 'watchdog-page'
  }
  // {
  //     path: '/error',
  //     component: PageError,
  //     name: 'error',
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
