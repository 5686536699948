import BaseApiService from "./BaseApiService"
import NoShowProject  from "@/interfaces/NoShowProject"

/**
 * Service class for handling operations related to no-show projects.
 */
export default class NoShowProjectsService extends BaseApiService {

  /**
   * Fetches all no-show projects from the server.
   * @returns A promise that resolves with an array of no-show projects.
   */
  public async getAllNoShowProjects(): Promise<any> {
    return await this.makeRequest("get", "/no-show-project/get-all-no-show-projects")
  }

  /**
   * Fetches all no-show projects  that are available (not archived) from the server.
   * @returns A promise that resolves with an array of no-show projects.
   */
  public async getAllAvailableNoShowProjects(searchString: string): Promise<any> {
    return await this.makeRequest("get", "/no-show-project/get-all-no-show-projects", {
      type: "available", searchString: searchString
    })
  }

  /**
   * Retrieves a specific no-show project by its ID.
   * @param id The ID of the no-show project to retrieve.
   * @returns A promise that resolves with the specified no-show project.
   */
  public async getNoShowProject(id: number): Promise<any> {
    return await this.makeRequest("get", "/no-show-project/get-no-show-project-by-id", {
      id: id,
      expand: ""
    })
  }

  /**
   * Saves a new or updates an existing no-show project on the server.
   * @param noShowProject The no-show project to save or update.
   * @returns A promise that resolves with the result of the save operation.
   */
  public async saveNoShowProject(noShowProject: NoShowProject): Promise<any> {
    return await this.makeRequest("post", "/no-show-project/save-no-show-project", null, {
      noShowProject: noShowProject
    })
  }

  /**
   * Deletes a no-show project from the server.
   * @param noShowProject The no-show project to delete.
   * @returns A promise that resolves with the result of the delete operation.
   */
  public async deleteNoShowProject(noShowProject: NoShowProject): Promise<any> {
    return await this.makeRequest("get", "/no-show-project/delete-no-show-project", {
      id: noShowProject.id
    })
  }
}
