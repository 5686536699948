<template>
  <tr class="new-concert" :class="(mainConcertIndex === 0 ? 'td-bg-new' : (mainConcertIndex % 2 === 0) ? 'td-bg-even' : 'td-bg-odd')" v-if="mainConcert.showSeriesBlock || concert.isSelected">
    <td>
      <template v-if="mainConcert.temporaryComment">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-icon icon="mdi-comment" v-bind="props"></v-icon>
          </template>
          <span v-html="nl2br(mainConcert.temporaryComment)"></span>
        </v-tooltip>
      </template>
    </td>
    <td>
      <template v-if="mainConcert.showSeriesBlock">
        <v-checkbox v-model="concert.isSelected" hide-details="true"></v-checkbox>
      </template>
    </td>
    <td>{{ weekday(concert.date) }}</td>
    <td>{{ concert.date }}</td>
    <td>{{ concert.begin }}</td>
    <td>-</td>
    <td>{{ mainConcert.venueObj?.address?.city }}</td>
    <td>{{ mainConcert.venueObj?.name }}</td>
    <td>{{ mainConcert.capacity }}</td>
    <td>
      <v-checkbox v-model="mainConcert.selfPromoted" hide-details="true" :disabled="true"></v-checkbox>
    </td>

    <td class="text-center">
      <template v-if="!mainConcert.selfPromoted">
        <v-checkbox v-model="mainConcert.flatDeal" hide-details="true" :disabled="true"></v-checkbox>
      </template>
      <template v-else>
        -
      </template>
    </td>

    <td>{{ mainConcert.coPromoterObj?.name }}</td>

    <td>
      {{ mainConcert.selfPromoted ? mainConcert.arrangeurObj?.name : '-'}}
    </td>

    <td>
      <template v-if="!mainConcert.selfPromoted">
        <v-checkbox v-model="mainConcert.thirdPartyFunds" hide-details="true" :disabled="true"></v-checkbox>
      </template>
      <template v-else>
        <div class="text-center">-</div>
      </template>
    </td>

    <td></td>
  </tr>
</template>

<script setup lang="ts">
import Concert from "@/interfaces/Concert"
import { useDateFormatter } from "@/helpers/dateFormatter"
import { useString } from "@/helpers/string"

const { nl2br } = useString()

const props = defineProps<{
  concert: Concert,
  concertIndex: number,
  mainConcert: Concert,
  mainConcertIndex: number
}>()

const { getShortWeekday } = useDateFormatter()

const weekday = (date) => {
  return getShortWeekday(date)
}
</script>
