export function useDateFormatter() {

  function getShortWeekday(dateString: string | null) {
    if (!dateString) {
      return null
    }

    const weekdayShort = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]

    let myDate = getDateByDateString(dateString)

    if (!myDate) {
      return null
    }

    let dayNumber = myDate.getDay()

    return weekdayShort[dayNumber]
  }

  function getDateByDateString(dateString: string | null): null | Date {
    if (!dateString) {
      return null
    }

    let parts = dateString.split(".")
    return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
  }

  function getDateStringByDate(date: Date | null) {
    if (date !== null) {
      const day = date.getDate().toString().padStart(2, "0")
      const month = (date.getMonth() + 1).toString().padStart(2, "0")
      const year = date.getFullYear()

      return day + "." + month + "." + year
    }

    return null
  }

  function getTimeStringByDate(date: Date | null) {
    if (date !== null) {
      const hours = date.getHours().toString().padStart(2, "0")
      const minutes = date.getMinutes().toString().padStart(2, "0")
      const seconds = date.getSeconds().toString().padStart(2, "0")

      return hours + ":" + minutes + ":" + seconds
    }

    return null
  }

  function getDateTimeStringByDate(dateString: string | null) {
    if (dateString !== null) {
      const date = new Date(dateString)
      return getDateStringByDate(date) + ", " + getTimeStringByDate(date) + " Uhr"
    }

    return null
  }

  function getAllMonthsAsArray() {
    return [
      {
        value: "JAN",
        id: 1
      },
      {
        value: "FEB",
        id: 2
      },
      {
        value: "MAR",
        id: 3
      },
      {
        value: "APR",
        id: 4
      },
      {
        value: "MAY",
        id: 5
      },
      {
        value: "JUN",
        id: 6
      },
      {
        value: "JUL",
        id: 7
      },
      {
        value: "AUG",
        id: 8
      },
      {
        value: "SEP",
        id: 91
      },
      {
        value: "OCT",
        id: 10
      },
      {
        value: "NOV",
        id: 11
      },
      {
        value: "DEC",
        id: 12
      },
    ]
  }

  function getMonthStringByMonthNumber(monthNumber: number) {
    return getAllMonthsAsArray().filter(item => { return item.id === monthNumber })[0]?.value ?? null
  }

  return {
    getShortWeekday,
    getDateByDateString,
    getDateStringByDate,
    getDateTimeStringByDate,
    getAllMonthsAsArray,
    getMonthStringByMonthNumber
  }
}
