<template>
  <div :class="requirementAttributeType.id > 0 ? 'section-item' : ''">
    <div
      v-if="!isLoading"
      :class="sectionClass()"
    >
      <v-form
        ref="myForm"
        v-model="isValid"
        @submit.prevent="saveAttributeType"
      >
        <div class="d-flex flex-row section-row" v-on:dblclick="() => { if(requirementAttributeType.id > 0) edit() }">
          <div
            v-if="requirementAttributeType.id > 0 && !editMode"
            class="section-column centered d-none"
            style="width: 50px;"
          >
            <v-icon
              class="handle"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>

          <div class="flex-grow-1 section-column centered">
            <template v-if="isEdited || requirementAttributeType.id === 0 || requirementAttributeType.id === -1">
              <v-text-field
                v-model="requirementAttributeType.description"
                :disabled="!isEdited"
                :rules="requiredRule"
                :tabindex="tabindex * 10 + 1"
                label="Beschreibung *"
                @keyup="checkIfNewIsChanged"
              ></v-text-field>
            </template>

            <template v-else>
              {{ requirementAttributeType.description }}
            </template>
          </div>

          <div class="flex-grow-1 section-column centered v-col-2">
            <template v-if="isEdited || requirementAttributeType.id === 0 || requirementAttributeType.id === -1">
              <v-select
                v-model="requirementAttributeType.datatype"
                :disabled="!isEdited"
                :items="getAllDataTypes"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="tabindex * 10 + 2"
                item-title="name"
                item-value="type"
                label="Datentyp *"
                :rules="requiredRule"
              ></v-select>
            </template>

            <template v-else>
              {{ showDatatypename(requirementAttributeType.datatype) }}
            </template>
          </div>

          <div class="flex-grow-1 section-column v-col-2 centered" v-if="1 == 2">
            <template v-if="isEdited || requirementAttributeType.id === 0 || requirementAttributeType.id === -1">
              <v-text-field
                v-model="requirementAttributeType.systemKey"
                :disabled="!isEdited"
                :tabindex="tabindex * 10 + 3"
                label="Systemkey"
                @keyup="checkIfNewIsChanged"
              >
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  Aktuell sind nur die Werte "concert" und "checkbox" vorgesehen
                </v-tooltip>
              </v-text-field>
            </template>
            <template v-else>
              {{ requirementAttributeType.systemKey }}
            </template>
          </div>

          <div class="section-column" style="width: 210px">
            <template v-if="isEdited">
              <div class="d-flex justify-end">
                <SaveButton
                  :disabled="isSaving"
                  :is-saving="isSaving"
                  :tabindex="tabindex * 10 + 7"
                  @callback="saveAttributeType"
                ></SaveButton>

                <CancelButton
                  :disabled="isSaving"
                  :is-saving="isSaving"
                  :tabindex="tabindex * 10 + 8"
                  @callback="reset"
                ></CancelButton>
              </div>
            </template>

            <template v-else-if="!editMode">
              <div class="d-flex justify-end">

                <template v-if="requirementAttributeType.datatype == 'item'">
                  <v-btn
                    @click="openListDialog(requirementAttributeType)"
                    :tabindex="tabindex * 10 + 6"
                  >
                    <v-icon icon="mdi-format-list-bulleted"></v-icon>

                    <v-tooltip
                      activator="parent"
                      location="top"
                    >
                      Listeneinträge bearbeiten
                    </v-tooltip>
                  </v-btn>
                </template>

                <EditButton
                  :disabled="isSaving"
                  :tabindex="tabindex * 10 + 7"
                  @callback="edit"
                ></EditButton>

                <DeleteButton
                  :archive="false"
                  :disabled="isSaving || !requirementAttributeType.canBeDeleted"
                  :is-saving="isSaving"
                  :tabindex="tabindex * 10 + 8"
                  @callback="deleteRequirementAttributeType"
                ></DeleteButton>
              </div>
            </template>
          </div>
        </div>

      </v-form>
    </div>
  </div>

  <v-dialog
    v-model="dialog"
    max-width="80dvw"
  >
    <v-card
      prepend-icon="mdi-format-list-bulleted"
      title="Einträge"
    >
      <div class="pa-4">
        <RequirementAttributeTypeItemBlock
          :requirementAttributeType="dialogItem"
        ></RequirementAttributeTypeItemBlock>
      </div>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Schliessen"
          @click="dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>

</template>

<script lang="ts" setup>

import { onMounted, ref }                from "vue"
import { useString }                     from "@/helpers/string"
import { VForm }                         from "vuetify/components"
import DeleteButton                      from "@/components/DeleteButton"
import SaveButton                        from "@/components/SaveButton"
import CancelButton                      from "@/components/CancelButton"
import EditButton                        from "@/components/EditButton"
import RequirementService                from "@/api/RequirementService"
import RequirementAttributeType          from "@/interfaces/RequirementAttributeType"
import RequirementAttributeTypeItemBlock from "@/components/RequirementAttributeTypeItemBlock"

onMounted(async () => {
  await init()
})

const dialog = ref(false)
const dialogItem = ref({})

const openListDialog = (attribute: RequirementAttributeType) => {
  dialog.value = true
  dialogItem.value = attribute
}

const props = defineProps<{
  requirementAttributeType: RequirementAttributeType,
  editId: number,
  isEdited: boolean,
  editMode: boolean,
  tabindex: number
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh"])

const isValid = ref()
const isLoading = ref(true)
const isSaving = ref(false)
const tab = ref()

const requirementService = ref(new RequirementService())
const { nl2br } = useString()

const id = ref()
const objNoReference = ref()
const myForm = ref({} as VForm)

const init = async () => {
  isLoading.value = false
  id.value = props.requirementAttributeType.id

  objNoReference.value = JSON.parse(JSON.stringify(props.requirementAttributeType))
}

const reset = () => {
  props.requirementAttributeType.description = objNoReference.value.description
  props.requirementAttributeType.systemKey = objNoReference.value.systemKey
  props.requirementAttributeType.datatype = objNoReference.value.datatype

  emit("resetEdit")

  resetValidation()
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.requirementAttributeType))

  emit("setEdit", { id: objNoReference.value.id })
}

const saveAttributeType = async () => {
  isSaving.value = true
  await validate()

  if (isValid.value) {
    await requirementService.value.saveAttributeType(props.requirementAttributeType).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allRequirementAttributeType = data.data.requirementAttributeType

        emit("refresh", { allRequirementAttributeType })
        reset()
      }
    })
  }

  isSaving.value = false
}

const deleteRequirementAttributeType = async () => {
  isSaving.value = true

  await requirementService.value.deleteAttributeType(props.requirementAttributeType).then(data => {
    if (!!data && !!data.data && data.data.success) {
      let list = data.data.requirementAttributeTypes
      emit("refresh", { list })
    }
  })

  isSaving.value = false
}

const checkIfNewIsChanged = () => {
  if (props.requirementAttributeType.id < 1) {

    // default
    props.requirementAttributeType.id = 0

    // if name is given - flag this object as new
    if (props.requirementAttributeType.description) {
      props.requirementAttributeType.id = -1
    }

    id.value = props.requirementAttributeType.id
    emit("setEdit", { id: id.value })
  }
}

const sectionClass = () => {
  if (!props.isEdited && props.editMode) {
    return "section-locked"
  }
  if (props.requirementAttributeType.id > 0 && !props.editMode) {
    return "section-block"
  }
  if (props.isEdited && props.editMode) {
    return "section-editing"
  }

  return ""
}

const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const getAllDataTypes = [
  { type: "string", name: "Text" },
  { type: "string_long", name: "Text lang" },
  { type: "int", name: "Ganzzahl" },
  { type: "float", name: "Kommazahl" },
  { type: "bool", name: "An/Aus" },
  { type: "file", name: "Datei" },
  { type: "price", name: "Preis" },
  { type: "item", name: "Auswahlfeld" }
]

const showDatatypename = (datatype: string) => {
  return (getAllDataTypes.find((item) => item.type == datatype)?.name ?? datatype)
}

</script>


<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
</style>
