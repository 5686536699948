<template>
  <template v-if="!isLoading">

    <div class="d-flex flex-row section-header">
      <h3 class="flex-grow-1">
        Container anlegen
      </h3>
    </div>

    <RequirementTemplateContainerItemEdit
      :editId="editId"
      :editMode="editId !== 0"
      :insertAfterItems="insertAfterItems()"
      :isEdited="editId === 0 || editId === -1"
      :requirementTemplateContainer="newRequirementTemplateContainer"
      :requirementAttributeTypes="requirementAttributeTypes"
      :rules="rules"
      @refresh="refresh"
      @resetEdit="resetEdit"
      @setEdit="setEdit"
    ></RequirementTemplateContainerItemEdit>

    <h3 class="mt-7 mb-3">Container-Übersicht</h3>
    <draggable
      :list="allRequirementTemplateContainer"
      item-key="id"
      group="container"
      v-bind="dragOptions"
      @end="draggingEnd"
      @start="dragging = true"
      tag="div"
    >
      <template #item="{ element }">
        <div>
          <RequirementTemplateContainerItemEdit
            :editId="editId"
            :editMode="editId !== 0"
            :insertAfterItems="[]"
            :isEdited="editId === element.id"
            :requirementTemplateContainer="element"
            :requirementAttributeTypes="requirementAttributeTypes"
            :rules="rules"
            @refresh="refresh"
            @resetEdit="resetEdit"
            @setEdit="setEdit"
          ></RequirementTemplateContainerItemEdit>
        </div>
      </template>

    </draggable>

  </template>
</template>

<script lang="ts" setup>

import { onMounted, reactive, Ref, ref, watch } from "vue"
import RequirementService                       from "@/api/RequirementService"
import draggable                                from "vuedraggable"
import RequirementTemplateContainerItemEdit     from "@/components/RequirementTemplateContainerItemEdit"
import RequirementTemplateContainer             from "@/interfaces/RequirementTemplateContainer"
import RequirementAttributeType                 from "@/interfaces/RequirementAttributeType"


onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())

const isLoading = ref(true)

const tab = ref("container")
const editId = ref()

const newRequirementTemplateContainer = new reactive({} as RequirementTemplateContainer)
const allRequirementTemplateContainer: Ref<RequirementTemplateContainer[]> = ref([])

const dragging = ref(false)

const props = defineProps<{
  requirementTab?: string,
  requirementAttributeTypes: RequirementAttributeType[]
}>()

const emit = defineEmits(["setEditMode", "refresh"])

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: false,
  scrollSensitivity: 200,
  handle: ".handle"
})

const rules = ref([])

const init = async () => {
  newRequirementTemplateContainer.id = -1

  await requirementService.value.getAllContainers().then(data => {
    if (data.success) {
      allRequirementTemplateContainer.value = data.data
    }
  })

  await requirementService.value.getAllContainerRules().then(result => {
    if( result.success && result.data )
    {
      rules.value = result.data
    }
  })

  editId.value = 0
  isLoading.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = async (val) => {
  await requirementService.value.getAllContainers().then(data => {
    if (data.success) {
      allRequirementTemplateContainer.value = data.data
    }
  })

  emit("refresh")
}

watch(() => editId.value,
  () => {
    emit("setEditMode", { editMode: (editId.value !== 0) })
  }
)

const draggingEnd = () => {
  requirementService.value.resortTemplateContainer(allRequirementTemplateContainer.value).then(data => {
    if (data.success) {
      allRequirementTemplateContainer.value = data.data.RequirementTemplateContainers
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allRequirementTemplateContainer.value.forEach(item => {
    arr.push({ newSortorder: (item.sortorder + 5), name: item.name })
  })

  return arr
}

</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
