<template>
  <template v-if="!isLoading">
    <div class="d-flex flex-row section-header">
      <div class="flex-grow-1">
        Neuanlage
      </div>
    </div>

    <RequirementTemplateAttributeEdit
      :editId="editId"
      :editMode="editId !== 0"
      :insertAfterItems="insertAfterItems()"
      :isEdited="editId === 0 || editId === -1"
      :requirementTemplateAttribute="newRequirementTemplateAttribute"
      :requirementTemplateContainer="requirementTemplateContainer"
      :requirementAttributeTypes="requirementAttributeTypes"
      @refresh="refresh"
      @resetEdit="resetEdit"
      @setEdit="setEdit"
    ></RequirementTemplateAttributeEdit>

    <draggable
      :list="allRequirementTemplateAttribute"
      item-key="id"
      group="templateattributes"
      v-bind="dragOptions"
      @end="draggingEnd"
      @start="dragging = true"
      tag="div"
    >
      <template #item="{ element }">
        <RequirementTemplateAttributeEdit
          :editId="editId"
          :editMode="editId !== 0"
          :insertAfterItems="[]"
          :isEdited="editId === element.id"
          :requirementTemplateAttribute="element"
          :requirementTemplateContainer="requirementTemplateContainer"
          :requirementAttributeTypes="requirementAttributeTypes"
          @refresh="refresh"
          @resetEdit="resetEdit"
          @setEdit="setEdit"
        ></RequirementTemplateAttributeEdit>
      </template>
    </draggable>

  </template>
</template>

<script lang="ts" setup>

import { onMounted, reactive, Ref, ref, watch } from "vue"
import RequirementService                       from "@/api/RequirementService"
import draggable                                from "vuedraggable"
import RequirementTemplateAttributeEdit         from "@/components/RequirementTemplateAttributeEdit"
import RequirementTemplateContainer             from "@/interfaces/RequirementTemplateContainer"
import RequirementTemplateAttribute             from "@/interfaces/RequirementTemplateAttribute"
import RequirementTemplateContainerItemEdit     from "@/components/RequirementTemplateContainerItemEdit.vue"
import RequirementAttributeType                 from "@/interfaces/RequirementAttributeType"

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())
const isLoading = ref(true)
const editId = ref()
const dragging = ref(false)

const newRequirementTemplateAttribute = new reactive({} as RequirementTemplateAttribute)
const allRequirementTemplateAttribute: Ref<RequirementTemplateAttribute[]> = ref([])

const props = defineProps<{
  requirementTemplateContainer: RequirementTemplateContainer,
  requirementAttributeTypes: RequirementAttributeType[]
}>()

const emit = defineEmits(["setEditMode", "refresh"])

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: false,
  scrollSensitivity: 200,
  handle: ".handle"
})

const init = async () => {
  newRequirementTemplateAttribute.id = -1

  await refresh()
  editId.value = 0

  isLoading.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = async (val?) => {
  await requirementService.value.getAllTemplateAttributes().then(data => {
    if (data.success) {
      allRequirementTemplateAttribute.value = data.data.filter((a) => a.rtecId == props.requirementTemplateContainer.id)
    }
  })

  emit("refresh")
}

watch(() => editId.value,
  () => {
    emit("setEditMode", { editMode: (editId.value !== 0) })
  }
)

const draggingEnd = async () => {
  requirementService.value.resortTemplateAttribute(allRequirementTemplateAttribute.value).then(data => {
    if (data.success) {
      // use backwards response with collection of items / filter only current attribute-type-item
      allRequirementTemplateAttribute.value = data.data.RequirementTemplateAttribute.filter((a) => a.rtecId == props.requirementTemplateContainer.id)
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allRequirementTemplateAttribute.value.forEach(item => {
    arr.push({ newSortorder: (item.sortorder + 5), name: item.name })
  })

  return arr
}

</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
