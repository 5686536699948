<template>
  <tr
    :class="(concert.isNew ? 'new-concert' : '') + ' ' + ((concertIndex === 0 && concert.isNew && !concert.isMarkedForSave) ? 'td-bg-new' : (concertIndex % 2 === 0) ? 'td-bg-even' : 'td-bg-odd')"
  >
    <td>
      <template v-if="isEditable()">
        <v-dialog
          v-model="temporaryCommentDialog"
          max-width="600"
        >
          <template v-slot:activator="{ props: activatorProps }">
            <v-icon
              :icon="!concert.temporaryComment ? 'mdi-comment-outline' : 'mdi-comment'"
              v-bind="activatorProps"
            ></v-icon>
          </template>

          <v-card
            prepend-icon="mdi-comment-outline"
            title="Bemerkung"
          >
            <v-card-text>
              <v-textarea
                v-model="concert.temporaryComment"
                label="Bemerkung"
                required
              ></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="concert.temporaryComment = null; temporaryCommentDialog = false">Löschen</v-btn>

              <v-btn variant="tonal" @click="temporaryCommentDialog = false">Übernehmen</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-else-if="concert.temporaryComment">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-icon :icon="!concert.temporaryComment ? 'mdi-comment-outline' : 'mdi-comment'" v-bind="props"></v-icon>
          </template>
          <span v-html="nl2br(concert.temporaryComment)"></span>
        </v-tooltip>
      </template>
    </td>

    <td>
      <span v-if="concert.id">{{ concert.oracleCode ?? "- fehlt -" }}</span>
      <i v-else>neu</i>
    </td>

    <td>{{ weekday(concert.date) }}</td>

    <td>
      <template v-if="isEditable()">
        <div style="width: 150px" class="pt-5">
          <DatepickerInput
            :data-model="concert"
            field="date"
            :required="true"
            :clearable="false"
            return-object
          ></DatepickerInput>
        </div>
      </template>

      <template v-else>
        {{ concert.date }}
      </template>
    </td>

    <td>
      <template v-if="isEditable()">
        <v-text-field
          v-model="concert.begin"
          label="Beginn *"
          type="time"
          class="pt-5"
          style="width: 100px"
          :rules="concertBeginRule"
        ></v-text-field>
      </template>

      <template v-else>
        {{ concert.begin }}
      </template>
    </td>

    <td>
      <template v-if="concert.isNew">
        <v-btn
          @click="concert.showSeriesBlock = true"
          :disabled="concert.showSeriesBlock || !concert.isMarkedForSave || concerts.filter((item) => { return (!item.isMarkedForSave && item.isNew) || item.showSeriesBlock }).length > 0"
          size="small"
        >{{ (concert.seriesConcerts?.length ?? 0) === 0 ? "Anlegen" : "Anpassen" }}
        </v-btn>
      </template>
    </td>

    <td>{{ concert.venueObj?.address?.city }}</td>

    <td>
      <template v-if="isEditable()">
        <v-tooltip
          :text="concert.venueObj?.name ?? '-'"
          location="top"
          opacity="1"
          :disabled="!concert.venueObj?.name"
        >
          <template v-slot:activator="{ props }">
            <v-autocomplete
              label="Location *"
              class="pt-5"
              v-model="concert.venueObj"
              v-model:search="venueSearch"
              :loading="venueLoading"
              :items="venueItems"
              item-title="name"
              item-value="id"
              @update:modelValue="selectVenue"
              :custom-filter="customFilter"
              :no-data-text="noResultsText"
              :menu-props="{maxHeight: 320, eager: true}"
              :rules="concertVenueRule"
              v-bind="props"
              :single-line="asSingleLine"
              return-object
              clearable
            ></v-autocomplete>
            <input v-if="showFocusElem" ref="venueSelectedElem">
          </template>
        </v-tooltip>
      </template>

      <template v-else>
        {{ concert.venueObj.name }}
      </template>
    </td>

    <td>
      <template v-if="isEditable()">
        <v-text-field
          v-model="concert.capacity"
          label="Kap *"
          class="pt-5"
          style="width: 100px"
          :rules="concertCapacityRule"
        ></v-text-field>
      </template>

      <template v-else>
        {{ concert.capacity }}
      </template>
    </td>

    <td>
      <v-checkbox
        v-model="concert.selfPromoted"
        hide-details="true"
        :disabled="!isEditable()"
        @change="changeSelfPromoted()"
      ></v-checkbox>
    </td>

    <td class="text-center">
      <template v-if="!concert.selfPromoted">
        <v-checkbox v-model="concert.flatDeal" hide-details="true" :disabled="!isEditable()"></v-checkbox>
      </template>
      <template v-else>
        -
      </template>
    </td>

    <td>
      <template v-if="isEditable()">
        <v-tooltip
          :text="concert.coPromoterObj?.name ?? '-'"
          location="top"
          opacity="1"
          :disabled="!concert.coPromoterObj?.name"
        >
          <template v-slot:activator="{ props }">
            <v-autocomplete
              label="Co-Promoter"
              class="pt-5"
              v-model="concert.coPromoterObj"
              v-model:search="promoterSearch"
              :loading="promoterLoading"
              :items="promoterItems"
              item-title="name"
              item-value="id"
              @update:modelValue="selectPromoter"
              :custom-filter="customFilter"
              :no-data-text="noResultsText"
              :menu-props="{maxHeight: 320, eager: true}"
              :rules="concertPromoterRule"
              v-bind="props"
              :single-line="asSingleLine"
              return-object
              clearable
            ></v-autocomplete>
            <input v-if="showFocusElem" ref="promoterSelectedElem">
          </template>
        </v-tooltip>
      </template>

      <template v-else>
        {{ concert.coPromoterObj?.name }}
      </template>
    </td>

    <td>
      <template v-if="concert.selfPromoted">
        <template v-if="isEditable()">
          <v-tooltip
            :text="concert.arrangeurObj?.name ?? '-'"
            location="top"
            opacity="1"
            :disabled="!concert.arrangeurObj?.name"
          >
            <template v-slot:activator="{ props }">
              <v-autocomplete
                label="Arrangeur"
                class="pt-5"
                v-model="concert.arrangeurObj"
                v-model:search="arrangeurSearch"
                :loading="arrangeurLoading"
                :items="arrangeurItems"
                item-title="name"
                item-value="id"
                @update:modelValue="selectArrangeur"
                :custom-filter="customFilter"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                :rules="concertArrangeurRule"
                v-bind="props"
                :single-line="asSingleLine"
                return-object
                clearable
              ></v-autocomplete>
              <input v-if="showFocusElem" ref="arrangeurSelectedElem">
            </template>
          </v-tooltip>
        </template>

        <template v-else>
          {{ concert.arrangeurObj?.name ?? "-" }}
        </template>
      </template>

      <template v-else>
        -
      </template>
    </td>

    <td class="text-center">
      <template v-if="!concert.selfPromoted">
        <v-checkbox v-model="concert.thirdPartyFunds" hide-details="true" :disabled="!isEditable()"></v-checkbox>
      </template>
      <template v-else>
        -
      </template>
    </td>

    <td
      class="pt-1 pb-1 text-center"
      style="width: 110px; white-space: nowrap"
    >
      <template v-if="concert.isNew">
        <v-btn
          @click="markForSave(concert, concertIndex)"
          :disabled="concert.isMarkedForSave"
          size="x-small"
          :icon="!concert.isMarkedForSave ? 'mdi-content-save' : 'mdi-check'"
        >
        </v-btn>
      </template>

      <template v-if="concert.isNew">
        <v-btn
          @click="deleteConcert(concertIndex)"
          size="x-small"
          icon="mdi-delete"
          class="ml-1"
        >
          <v-icon class="mdi-delete"></v-icon>
        </v-btn>
      </template>
    </td>
  </tr>

  <template v-if="concert.showSeriesBlock || (concert.seriesConcerts?.length ?? 0) !== 0">
    <tr class="new-concert" v-if="!concert.seriesConcerts || concert.seriesConcerts.length === 0">
      <td colspan="2">Neue Konzerte</td>
      <td colspan="20">
        <div class="float-left pt-5">

          <div style="width: 150px">
            <DatepickerInput
              :data-model="concert"
              field="toDate"
              :required="true"
              :clearable="true"
              return-object
            ></DatepickerInput>
          </div>
        </div>

        <div class="float-left pt-5">
          <v-btn
            @click="addSubConcerts(concert)"
            size="large"
            icon="mdi-arrow-right-bold-box-outline"
            class="ml-3"
          ></v-btn>
        </div>

        <div class="float-left pt-5">
          <v-btn
            @click="resetSubConcerts(concert)"
            size="large"
            icon="mdi-close-thick"
            class="ml-3"
          ></v-btn>
        </div>
      </td>
    </tr>

    <template v-else>
      <template v-for="(concertItem, concertItemIndex) in concert.seriesConcerts">
        <ConcertCreateSubItem
          :concert-index="concertItemIndex"
          :concert="concertItem"
          :main-concert="concert"
          :main-concert-index="concertIndex"
        ></ConcertCreateSubItem>
      </template>

      <tr class="new-concert" v-if="concert.showSeriesBlock">
        <td colspan="2"></td>
        <td colspan="20" class="pt-2 pb-2">
          <v-btn @click="saveSubConcerts(concert)">Übernehmen</v-btn>
          <v-btn @click="resetSubConcerts(concert)" class="ml-2">Alle löschen</v-btn>
        </td>
      </tr>
    </template>
  </template>
</template>

<style>
.new-concert {
  background-color: rgba(141, 176, 213, 0.4);
}

.new-concert .dp-field {
  background: #C9D7E5;
}

.new-concert .dp-field:hover, .new-concert .dp-field:focus {
  background: #C2CFDD;
}
</style>

<script setup lang="ts">
import { reactive, ref, watch } from "vue"
import Concert                  from "@/interfaces/Concert"
import { useDateFormatter }     from "@/helpers/dateFormatter"
import ConcertCreateSubItem     from "@/components/ConcertCreateSubItem.vue"
import { useNumberFormatter }   from "@/helpers/formatters"
import CompanyService           from "@/api/concert/CompanyService"
import { useString }            from "@/helpers/string"
import DatepickerInput          from "@/components/DatepickerInput.vue"

const props = defineProps<{
  concert: Concert,
  concerts: Concert[],
  concertIndex: number
}>()

const companyService = ref(new CompanyService())

const venueLoading = false
const venueItems = ref()
const venueSearch = ref()
const venueSearchTimer = ref()
const venueSelectedElem = ref()

const promoterLoading = false
const promoterItems = ref()
const promoterSearch = ref()
const promoterSearchTimer = ref()
const promoterSelectedElem = ref()

const arrangeurLoading = false
const arrangeurItems = ref()
const arrangeurSearch = ref()
const arrangeurSearchTimer = ref()
const arrangeurSelectedElem = ref()

const temporaryCommentDialog = ref(false)

const emit = defineEmits(["save", "saveConcert", "addNewConcert"])

const showFocusElem = ref(false)

const noResultsText = "Keine Ergebnisse"

const { getShortWeekday, getDateByDateString, getDateStringByDate } = useDateFormatter()
const { formatForCalendar } = useNumberFormatter()
const { nl2br } = useString()

const asSingleLine = ref(true)

watch(() => venueSearch,
  (currentValue) => {
    clearTimeout(venueSearchTimer.value)
    venueSearchTimer.value = setTimeout(() => {
      if (currentValue.value.length > 2) {
        companyService.value.findVenues(currentValue.value).then(data => {
          venueItems.value = data.data
        })
      } else {
        venueItems.value = []
      }
    }, 500)
  },
  { deep: true }
)

const selectVenue = async () => {
  if (props.concert.venueObj?.id) {
    await companyService.value.getVenue(props.concert.venueObj.id).then(data => {
      props.concert.venueObj = data.data ?? null
    })
  }
}

watch(() => promoterSearch,
  (currentValue) => {
    clearTimeout(promoterSearchTimer.value)
    promoterSearchTimer.value = setTimeout(() => {
      if (currentValue.value.length > 2) {
        companyService.value.findCoPromoters(currentValue.value).then(data => {
          promoterItems.value = data.data
        })
      } else {
        promoterItems.value = []
      }
    }, 500)
  },
  { deep: true }
)

const selectPromoter = async () => {
  if (props.concert.coPromoterObj?.id) {
    await companyService.value.getCoPromoter(props.concert.coPromoterObj.id).then(data => {
      props.concert.coPromoterObj = data.data ?? null
    })
  }
}

watch(() => arrangeurSearch,
  (currentValue) => {
    clearTimeout(arrangeurSearchTimer.value)
    arrangeurSearchTimer.value = setTimeout(() => {
      if (currentValue.value.length > 2) {
        companyService.value.findArrangeurs(currentValue.value).then(data => {
          arrangeurItems.value = data.data
        })
      } else {
        arrangeurItems.value = []
      }
    }, 500)
  },
  { deep: true }
)

const selectArrangeur = async () => {
  if (props.concert.arrangeurObj?.id) {
    await companyService.value.getArrangeur(props.concert.arrangeurObj.id).then(data => {
      props.concert.arrangeurObj = data.data ?? null
    })
  }
}

const customFilter = () => {
  // this just disables the vuetify filter, filtering items is done via axios
  return true
}

const addSubConcerts = (concert) => {
  let fromDate = getDateByDateString(concert.date ?? null)
  let toDate = getDateByDateString(concert.toDate ?? null)

  concert.seriesConcerts = []

  if (fromDate !== null && toDate !== null) {
    let startDate = fromDate
    startDate.setDate(startDate.getDate() + 1)

    for (let date = startDate; date <= toDate; date.setDate(date.getDate() + 1)) {
      let tmpConcert = new reactive({} as Concert)
      tmpConcert.date = getDateStringByDate(date)
      tmpConcert.begin = concert.begin
      tmpConcert.isSelected = true

      concert.seriesConcerts.push(tmpConcert)
    }
  }
}

const resetSubConcerts = (concert: Concert) => {
  concert.seriesConcerts = []
  concert.toDate = concert.date
  concert.showSeriesBlock = false
}


const changeSaveButton = () => {
  if (props.concert.isValid) {
    props.concert.isMarkedForSave = true
  }
}

const markForSave = async (concert: Concert, concertIndex: number) => {
  emit("saveConcert", concertIndex, changeSaveButton)
}

const saveSubConcerts = (concert: Concert) => {
  concert.seriesConcerts.forEach((item) => {
    item.isMarkedForSave = true
  })

  concert.showSeriesBlock = false
}

const weekday = (date) => {
  return getShortWeekday(date)
}

const isEditable = () => {
  return props.concert.isNew && !props.concert.isMarkedForSave
}

const deleteConcert = (concertIndex) => {
  props.concerts.splice(concertIndex, 1)
}

const changeSelfPromoted = () => {
  if (props.concert.selfPromoted) {
    props.concert.flatDeal = false
  }
}

const concertBeginRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const concertVenueRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const concertCapacityRule = ref([
  v => {
    if (!v) {
      return "Darf nicht leer sein"
    }

    if (isNaN(parseFloat(v))) {
      return "Muss Zahl sein"
    }

    return true
  }
])

const concertPromoterRule = ref([])

const concertArrangeurRule = ref([])

</script>
