import axios from 'axios';

export default class BaseApiService {

  constructor() {
    const token = sessionStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  }

  /**
   * Build and return the api-url.
   * @param {string} endpoint The endpoint.
   */
  getApiUrl(endpoint: string) {
    // @ts-ignore
    return import.meta.env.VITE_API_URL + endpoint;
  }

  /**
   * Process a successful result (status code 200).
   * @param res The result.
   */
  processResult(res) {
    return {
      success: !!res.data,
      data: res.data,
      headers: res.headers
    };
  }

  /**
   * Process an erroneous result (exception, status code <> 200).
   * @param res The result/error.
   */
  processErrorResult(res) {
    let errors = [];
    if (!res) {
      location.href = import.meta.env.VITE_CONCERTON_URL;
    } else {
      switch (res.status) {
        case 401:
        case 403:
          // not authenticated => redirect to login page
          // @ts-ignore
          location.href = import.meta.env.VITE_CONCERTON_URL;
          break;
        case 422:
          // validation errors
          errors = res.data;
          break;
      }
    }
    return {
      success: false,
      data: null,
      headers: !!res ? res.headers : null,
      errors: errors,
    };
  }

  async makeRequest<T>(method: 'get' | 'post', endpoint: string, params?: any, data?: any, responseType?: any): Promise<{
    headers: any;
    data: null;
    success: boolean;
    errors?: any[]
  }> {
    const url = this.getApiUrl(endpoint);
    const config = { headers: {}, responseType: 'json' };

    if (data) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    if (responseType) {
      config.responseType = responseType
    }

    try {
      const response = await axios.request({
        method,
        url,
        params,
        data,
        ...config
      });
      return this.processResult(response);
    } catch (error) {
      return this.processErrorResult(error.response || error.message);
    }
  }
}
