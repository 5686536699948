<template>

  <v-card v-if="incomingInvoice.incomingInvoiceTours.length > 0 || incomingInvoice.incomingInvoiceNoShowProjects?.length > 0">
    <!-- upper block tour tabs -->
    <v-tabs
      v-model="incomingInvoice.selectedTab"
      bg-color="grey-lighten-2"
      center-active
    >
      <template
        v-if="incomingInvoice.incomingInvoiceNoShowProjects.length > 0"
      >
        <v-tab
          :value="'tab-0'"
        >
          Non-Show-Projects
        </v-tab>
      </template>

      <template
        v-for="(tourItem, index) in incomingInvoice.incomingInvoiceTours"
      >
        <v-tab
          :value="'tab-' + (10000 + index)"
        >
          {{ tourItem.name }}
          <v-btn
            variant="tonal"
            @click="removeTab(index)"
            v-if="canRemoveTab(tourItem)"
            size="x-small"
            class="btn-remove-tab"
            icon="mdi-close"
          >
          </v-btn>
        </v-tab>
      </template>
    </v-tabs>

    <!-- upper block with concerts -->
    <v-window
      v-model="incomingInvoice.selectedTab"
    >
      <v-window-item
        v-if="incomingInvoice.incomingInvoiceNoShowProjects.length > 0"
        :key="0"
        :value="'tab-0'"
      >
        <v-card>
          <v-card-text>
            <v-table density="compact">
              <thead>
              <tr>
                <th style="width: 50px;">
                  <v-checkbox-btn @change="selectAllNoShowProjects($event)"></v-checkbox-btn>
                </th>
                <th class="text-left">Name</th>
                <th class="text-left">Description</th>
                <th class="text-left">Oracle-ID</th>
                <th class="text-left">Department</th>
                <th class="text-left">Gültigkeitsjahr</th>
                <th class="text-left">Wählbar bis</th>

              </tr>
              </thead>

              <tbody>
              <template
                v-for="item in incomingInvoice.incomingInvoiceNoShowProjects"
              >
                <tr>
                  <td>
                    <v-checkbox-btn v-model="item.isSelected"></v-checkbox-btn>
                  </td>
                  <td>{{ item.name }}</td>
                  <td><span v-html="nl2br(item.description ?? '')"></span></td>
                  <td>{{ item.oracleCode }}</td>
                  <td>{{ item.department }}</td>
                  <td>{{ item.validYear }}</td>
                  <td>{{ item.validUntil }}</td>
                </tr>
              </template>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item
        v-for="(tourItem, tourIndex) in incomingInvoice.incomingInvoiceTours"
        :key="tourIndex"
        :value="'tab-' + (10000 + tourIndex)"
      >
        <v-card>
          <v-card-text>
            <v-table density="compact">
              <thead>
              <tr>
                <th style="width: 50px;"></th>
                <th style="width: 50px;">
                  <v-checkbox-btn @change="selectAllForTour(tourItem.tourId, $event)"></v-checkbox-btn>
                </th>
                <th class="text-left">Oracle-ID</th>
                <th class="text-left">Datum</th>
                <th class="text-left">Land</th>
                <th class="text-left">Stadt</th>
                <th class="text-left">Venue</th>
                <th class="text-left">Promoter</th>
                <th class="text-left">Beschreibung</th>
              </tr>
              </thead>

              <tbody>
              <template
                v-for="(incomingInvoiceContainer, incomingInvoiceContainerIndex) in tourItem.incomingInvoiceContainers"
              >
                <!-- group row -->
                <tr
                  v-if="incomingInvoiceContainer.incomingInvoiceConcerts.length > 1"
                  :class="incomingInvoiceContainer.showConcerts ? 'selected-group-row' : ''"
                >
                  <td>
                    <v-btn
                      size="small"
                      @click="incomingInvoiceContainer.showConcerts = !incomingInvoiceContainer.showConcerts"
                    >
                      <v-icon
                        icon="mdi-plus-box-outline"
                        v-if="!incomingInvoiceContainer.showConcerts"
                      ></v-icon>

                      <v-icon
                        icon="mdi-minus-box-outline"
                        v-if="incomingInvoiceContainer.showConcerts"
                      ></v-icon>

                      ({{ incomingInvoiceContainer.incomingInvoiceConcerts.length }})
                    </v-btn>
                  </td>

                  <td>
                    <v-checkbox-btn
                      v-model="incomingInvoiceContainer.selectedState"
                      :indeterminate="incomingInvoiceContainer.selectedState === null"
                      @change="incomingInvoiceContainerStateChange(incomingInvoiceContainer)"
                    ></v-checkbox-btn>
                  </td>

                  <td>{{ incomingInvoiceContainer.oracleId }}</td>

                  <td>
                    <a
                      class="link"
                      @click="incomingInvoiceContainer.showConcerts = !incomingInvoiceContainer.showConcerts"
                    >
                      <span>
                        {{ formatDateRange(incomingInvoiceContainer.dateFrom, incomingInvoiceContainer.dateTo) }}
                      </span>
                    </a>
                  </td>

                  <td>{{ incomingInvoiceContainer.countryIso2 }}</td>
                  <td>{{ incomingInvoiceContainer.city }}</td>
                  <td>{{ incomingInvoiceContainer.venue }}</td>
                  <td>{{ incomingInvoiceContainer.promoter }}</td>
                  <td>{{ incomingInvoiceContainer.desc }}</td>
                </tr>

                <!-- concert items -->
                <tr
                  v-for="(concertItem, concertIndex) in incomingInvoiceContainer.incomingInvoiceConcerts"
                  v-if="incomingInvoiceContainer.showConcerts || incomingInvoiceContainer.incomingInvoiceConcerts.length === 1"
                  :class="incomingInvoiceContainer.showConcerts ? 'selected-group-item' : ''"
                >
                  <td>
                    <template v-if="incomingInvoiceContainer.incomingInvoiceConcerts.length === 1">
                      <v-btn
                        size="small"
                        :disabled="true"
                      >
                        <v-icon icon="mdi-circle-box-outline"></v-icon>
                        (1)
                      </v-btn>
                    </template>
                  </td>

                  <td>
                    <v-checkbox-btn
                      v-model="concertItem.isSelected"
                      @change="checkOrUncheckContainer(incomingInvoiceContainer)"
                    ></v-checkbox-btn>
                  </td>

                  <td>{{ concertItem.oracleId }}</td>

                  <template v-if="concertItem.type === 'concert'">
                    <td>
                      <a :href="linkToConcert(concertItem.conId)" target="_blank">
                      <span>
                          {{ concertItem.date }}
                      <v-tooltip
                        activator="parent"
                        location="top"
                      >
                        Konzertseite öffnen
                      </v-tooltip>
                      </span>
                      </a>
                    </td>

                    <td>{{ concertItem.countryIso2 }}</td>
                    <td>{{ concertItem.city }}</td>
                    <td>{{ concertItem.venue }}</td>
                    <td>{{ concertItem.promoter }}</td>
                    <td>{{ concertItem.desc }}</td>
                  </template>

                  <template v-else>
                    <td colspan="6">{{ concertItem.desc }}</td>
                  </template>
                </tr>
              </template>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>

  <v-card
    v-if="anyConcertSelected(incomingInvoice) || anyNoShowProjectsSelected(incomingInvoice)"
    class="mt-7"
    :title="numberOfSelectedConcertsAsText()"
  >
    <v-card-text>
      <v-container>
        <v-row dense v-if="anyConcertSelected(incomingInvoice)">
          <v-col cols="12">
            <v-switch
              label="Details zu Konzerten anzeigen"
              color="#D9112D"
              v-model="showDetailsForShows"
              hide-details="true"
              inline
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-switch
              label="Erfassung von Kostenpositionsnummern"
              color="#D9112D"
              v-model="incomingInvoice.flgUseCostPositionNumber"
              hide-details="true"
              inline
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <!-- bottom block with selected concerts and costs -->
      <v-table density="compact" class="table-cost-assignment">
        <thead v-if="anyNoShowProjectsSelected(incomingInvoice)">
        <tr>
          <th style="width: 50px;"></th>
          <th style="width: 50px;">
            <v-checkbox-btn @click="selectAllNoShowProjectsForCostAssignment($event)"></v-checkbox-btn>
          </th>
          <th class="text-left">Name</th>
          <th class="text-left">Beschreibung</th>
          <th class="text-left">Oracle-ID</th>
          <th class="text-left">Department</th>
          <th class="text-left">Gültigkeitsjahr</th>
          <th class="text-left">Wählbar bis</th>

          <th class="text-left" v-if="anyConcertSelected(incomingInvoice) && showDetailsForShows" colspan="2"></th>

          <template v-for="group in incomingInvoice.incomingInvoiceGroups">
            <th class="text-left" v-if="group.invoiceGroup" :colspan="incomingInvoice.flgUseCostPositionNumber ? 4 : 3">
              {{ group.invoiceGroup ? group.invoiceGroup.name : null }}
            </th>
          </template>
        </tr>
        </thead>

        <template v-for="(noShowProjectItem) in selectedNoShowProjects(incomingInvoice)">
          <tbody>
          <tr>
            <td></td>
            <td>
              <v-checkbox-btn v-model="noShowProjectItem.isSelectedForCostAssignment"></v-checkbox-btn>
            </td>
            <td>{{ noShowProjectItem.name }}</td>
            <td><span v-html="nl2br(noShowProjectItem.description ?? '')"></span></td>
            <td>{{ noShowProjectItem.oracleCode }}</td>
            <td>{{ noShowProjectItem.department }}</td>
            <td>{{ noShowProjectItem.validYear }}</td>
            <td>{{ noShowProjectItem.validUntil }}</td>

            <td v-if="anyConcertSelected(incomingInvoice) && showDetailsForShows" colspan="2"></td>

            <template v-for="incomingInvoicePosition in noShowProjectItem.incomingInvoicePositions">
              <td>
                <v-text-field
                  v-model="incomingInvoicePosition.desc"
                  label="Zusatzinfo"
                  density="compact"
                  style="min-width: 130px;"
                />
              </td>

              <td v-if="incomingInvoice.flgUseCostPositionNumber">
                <v-text-field
                  v-model="incomingInvoicePosition.number"
                  label="Nummer"
                  density="compact"
                  style="min-width: 70px;"
                />
              </td>

              <td>
                <CurrencyInput
                  v-model="incomingInvoicePosition.amount"
                  :options="{ precision: { min: 2, max: 6 } }"
                  :currency="incomingInvoice.currency"
                  :compact="true"
                  label="Kosten"
                  style="min-width: 150px;"

                />
              </td>

              <td>
                <v-btn
                  icon="mdi-calculator"
                  size="small"
                  style="margin-top: -24px;"
                >
                  <v-icon icon="mdi-calculator"></v-icon>
                  <v-overlay
                    v-model="incomingInvoicePosition.calculatorOverlay"
                    location-strategy="connected"
                    scroll-strategy="block"
                    activator="parent"
                    :close-on-content-click="false"
                  >
                    <v-card class="pa-2">
                      <v-text-field
                        v-model="incomingInvoicePosition.formula"
                        style="width:300px;"
                        :errorMessages="calculationError"
                      ></v-text-field>
                      <v-btn
                        color="success"
                        @click="close(incomingInvoicePosition, null, 'position')"
                        class="mt-2"
                      >Übernehmen
                      </v-btn>
                      <v-btn
                        @click="incomingInvoicePosition.calculatorOverlay = false"
                        class="mt-2 float-right"
                      >Abbrechen
                      </v-btn>
                    </v-card>
                  </v-overlay>
                </v-btn>
              </td>
            </template>
          </tr>
          </tbody>
        </template>

        <thead v-if="anyConcertSelected(incomingInvoice)">
        <tr>
          <th style="width: 50px;"></th>
          <th style="width: 50px;">
            <v-checkbox-btn @click="selectAllConcertsForCostAssignment($event)"></v-checkbox-btn>
          </th>
          <th class="text-left">OracleID</th>
          <th class="text-left">Datum</th>
          <th class="text-left">Tournee</th>
          <th class="text-left" v-if="!showDetailsForShows"></th>
          <th class="text-left" v-if="showDetailsForShows">Land</th>
          <th class="text-left" v-if="showDetailsForShows">Stadt</th>
          <th class="text-left" v-if="showDetailsForShows">Venue</th>
          <th class="text-left" v-if="showDetailsForShows">Promoter</th>
          <th class="text-left" v-if="showDetailsForShows">Beschreibung</th>

          <th class="text-left" v-if="!showDetailsForShows && anyNoShowProjectsSelected" colspan="2"></th>

          <template v-for="group in incomingInvoice.incomingInvoiceGroups">
            <th class="text-left" v-if="group.invoiceGroup" :colspan="incomingInvoice.flgUseCostPositionNumber ? 4 : 3">
              {{ group.invoiceGroup ? group.invoiceGroup.name : null }}
            </th>
          </template>
        </tr>
        </thead>

        <tbody>
        <template v-for="(tourItem, tourIndex) in incomingInvoice.incomingInvoiceTours">

          <template
            v-for="(incomingInvoiceContainer, incomingInvoiceContainerIndex) in tourItem.incomingInvoiceContainers"
          >
            <template
              v-if="incomingInvoiceContainer.selectedState === true || incomingInvoiceContainer.selectedState === null"
            >
              <!-- group row -->
              <tr
                v-if="incomingInvoiceContainer.incomingInvoiceConcerts.length > 1"
                :class="incomingInvoiceContainer.showConcertsForCostAssignment ? 'selected-group-row' : ''"
              >
                <td>
                  <v-btn
                    size="small"
                    @click="incomingInvoiceContainer.showConcertsForCostAssignment = !incomingInvoiceContainer.showConcertsForCostAssignment"
                  >
                    <v-icon
                      icon="mdi-plus-box-outline"
                      v-if="!incomingInvoiceContainer.showConcertsForCostAssignment"
                    ></v-icon>
                    <v-icon
                      icon="mdi-minus-box-outline"
                      v-if="incomingInvoiceContainer.showConcertsForCostAssignment"
                    ></v-icon>
                    ({{ getSelectedConcertCountForContainer(incomingInvoiceContainer) }})
                  </v-btn>
                </td>

                <td>
                  <v-checkbox-btn
                    v-model="incomingInvoiceContainer.selectedStateForCostAssignment"
                    :indeterminate="incomingInvoiceContainer.selectedStateForCostAssignment === null"
                    @change="incomingInvoiceContainerStateForCostAssignmentChange(incomingInvoiceContainer)"
                  ></v-checkbox-btn>
                </td>

                <td>{{ incomingInvoiceContainer.oracleId }}</td>

                <td>
                  <a
                    class="link"
                    @click="incomingInvoiceContainer.showConcertsForCostAssignment = !incomingInvoiceContainer.showConcertsForCostAssignment"
                  >
                    <span>
                        {{ formatDateRange(incomingInvoiceContainer.dateFrom, incomingInvoiceContainer.dateTo) }}
                    </span>
                  </a>
                </td>

                <td>{{ incomingInvoiceContainer.tourName }}</td>
                <td v-if="!showDetailsForShows && anyNoShowProjectsSelected"></td>
                <td v-if="showDetailsForShows">{{ incomingInvoiceContainer.countryIso2 }}</td>
                <td v-if="showDetailsForShows">{{ incomingInvoiceContainer.city }}</td>
                <td v-if="showDetailsForShows">{{ incomingInvoiceContainer.venue }}</td>
                <td v-if="showDetailsForShows">{{ incomingInvoiceContainer.promoter }}</td>
                <td v-if="showDetailsForShows">{{ incomingInvoiceContainer.desc }}</td>

                <template v-for="incomingInvoicePosition in incomingInvoiceContainer.incomingInvoicePositions">
                  <td>
                    <v-text-field
                      v-if="checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'desc')"
                      v-model="incomingInvoicePosition.desc"
                      label="Zusatzinfo"
                      density="compact"
                      style="min-width: 130px;"
                      @change="setValueForGroup(incomingInvoiceContainer, incomingInvoicePosition, 'desc')"
                    />

                    <v-text-field
                      v-if="!checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'desc')"
                      model-value="Diverse"
                      label="Zusatzinfo"
                      density="compact"
                      style="min-width: 130px;"
                      :disabled="true"
                    />
                  </td>

                  <td v-if="incomingInvoice.flgUseCostPositionNumber">
                    <v-text-field
                      v-if="checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'number')"
                      v-model="incomingInvoicePosition.number"
                      label="Nummer"
                      density="compact"
                      style="min-width: 70px;"
                      @change="setValueForGroup(incomingInvoiceContainer, incomingInvoicePosition, 'number')"
                    />

                    <v-text-field
                      v-if="!checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'number')"
                      model-value="Diverse"
                      label="Nummer"
                      density="compact"
                      style="min-width: 130px;"
                      :disabled="true"
                    />
                  </td>

                  <td>
                    <CurrencyInput
                      v-model="incomingInvoicePosition.amount"
                      :options="{ precision: { min: 2, max: 6 } }"
                      :currency="incomingInvoice.currency"
                      :compact="true"
                      label="Kosten"
                      style="min-width: 150px;"
                      @change="setValueForGroup(incomingInvoiceContainer, incomingInvoicePosition, 'amount')"
                      :disabled="!checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'amount')"
                    />
                  </td>

                  <td>
                    <v-btn
                      icon="mdi-calculator"
                      size="small"
                      style="margin-top: -24px;"
                      v-if="checkIfAllSelectedPositionsAreIdentical(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'amount')"
                    >
                      <v-icon icon="mdi-calculator"></v-icon>
                      <v-overlay
                        v-model="incomingInvoicePosition.calculatorOverlay"
                        location-strategy="connected"
                        scroll-strategy="block"
                        activator="parent"
                        :close-on-content-click="false"
                      >
                        <v-card class="pa-2">
                          <v-text-field
                            v-model="incomingInvoicePosition.formula"
                            style="width:300px;"
                            :errorMessages="calculationError"
                          ></v-text-field>
                          <v-btn
                            color="success"
                            @click="close(incomingInvoicePosition, incomingInvoiceContainer, 'group')"
                            class="mt-2"
                          >Übernehmen
                          </v-btn>
                          <v-btn
                            @click="incomingInvoicePosition.calculatorOverlay = false"
                            class="mt-2 float-right"
                          >Abbrechen
                          </v-btn>
                        </v-card>
                      </v-overlay>
                    </v-btn>
                  </td>
                </template>

              </tr>

              <!-- concert items -->
              <template v-for="(concertItem, concertIndex) in incomingInvoiceContainer.incomingInvoiceConcerts">
                <tr
                  v-if="concertItem.isSelected && (incomingInvoiceContainer.showConcertsForCostAssignment || incomingInvoiceContainer.incomingInvoiceConcerts.length === 1)"
                  :class="incomingInvoiceContainer.showConcertsForCostAssignment ? 'selected-group-item' : ''"
                >
                  <td>
                    <template v-if="incomingInvoiceContainer.incomingInvoiceConcerts.length === 1">
                      <v-btn
                        size="small"
                        :disabled="true"
                      >
                        <v-icon icon="mdi-circle-box-outline"></v-icon>
                        (1)
                      </v-btn>
                    </template>
                  </td>

                  <td>
                    <v-checkbox-btn
                      v-model="concertItem.isSelectedForCostAssignment"
                      @change="checkOrUncheckContainerForCostAssignment(incomingInvoiceContainer)"
                    ></v-checkbox-btn>
                  </td>

                  <td>{{ concertItem.oracleId }}</td>

                  <template v-if="concertItem.type === 'concert'">
                    <td>
                      <a :href="linkToConcert(concertItem.conId)" target="_blank">
                        <span>
                            {{ concertItem.date }}
                        <v-tooltip
                          activator="parent"
                          location="top"
                        >
                          Konzertseite öffnen
                        </v-tooltip>
                        </span>
                      </a>
                    </td>

                    <td>{{ concertItem.artistName }}</td>

                    <td v-if="showDetailsForShows">{{ concertItem.countryIso2 }}</td>
                    <td v-if="showDetailsForShows">{{ concertItem.city }}</td>
                    <td v-if="showDetailsForShows">{{ concertItem.venue }}</td>
                    <td v-if="showDetailsForShows">{{ concertItem.promoter }}</td>
                    <td v-if="showDetailsForShows">{{ concertItem.desc }}</td>
                  </template>

                  <template v-else>
                    <td></td>
                    <td>
                    <span>
                    {{ concertItem.artistName }}
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >
                      {{ concertItem.tourName }}
                    </v-tooltip>
                      </span>
                    </td>
                    <td colspan="5" v-if="showDetailsForShows">{{ concertItem.desc }}</td>
                  </template>

                  <td v-if="!showDetailsForShows && anyNoShowProjectsSelected" colspan="3"></td>

                  <template v-for="incomingInvoicePosition in concertItem.incomingInvoicePositions">
                    <td>
                      <v-text-field
                        v-model="incomingInvoicePosition.desc"
                        label="Zusatzinfo"
                        density="compact"
                        style="min-width: 130px;"
                        @change="checkAndSetGroup(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'desc')"
                      />
                    </td>

                    <td v-if="incomingInvoice.flgUseCostPositionNumber">
                      <v-text-field
                        v-model="incomingInvoicePosition.number"
                        label="Nummer"
                        density="compact"
                        style="min-width: 70px;"
                        @change="checkAndSetGroup(incomingInvoiceContainer, incomingInvoicePosition.groupId, 'number')"
                      />
                    </td>

                    <td>
                      <CurrencyInput
                        v-model="incomingInvoicePosition.amount"
                        :options="{ precision: { min: 2, max: 6 } }"
                        :currency="incomingInvoice.currency"
                        :compact="true"
                        label="Kosten"
                        style="min-width: 150px;"
                        @change="calculateSum(incomingInvoiceContainer, incomingInvoicePosition.groupId)"

                      />
                    </td>

                    <td>
                      <v-btn
                        icon="mdi-calculator"
                        size="small"
                        style="margin-top: -24px;"
                      >
                        <v-icon icon="mdi-calculator"></v-icon>
                        <v-overlay
                          v-model="incomingInvoicePosition.calculatorOverlay"
                          location-strategy="connected"
                          scroll-strategy="block"
                          activator="parent"
                          :close-on-content-click="false"
                        >
                          <v-card class="pa-2">
                            <v-text-field
                              v-model="incomingInvoicePosition.formula"
                              style="width:300px;"
                              :errorMessages="calculationError"
                            ></v-text-field>
                            <v-btn
                              color="success"
                              @click="close(incomingInvoicePosition, incomingInvoiceContainer, 'position')"
                              class="mt-2"
                            >Übernehmen
                            </v-btn>
                            <v-btn
                              @click="incomingInvoicePosition.calculatorOverlay = false"
                              class="mt-2 float-right"
                            >Abbrechen
                            </v-btn>
                          </v-card>
                        </v-overlay>
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </template>
        </tbody>

        <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td colspan="2" v-if="!showDetailsForShows"></td>
          <td colspan="4" v-if="showDetailsForShows && anyConcertSelected(incomingInvoice)"></td>
          <template v-for="group in incomingInvoice.incomingInvoiceGroups">
            <template v-if="group.invoiceGroup">
              <td></td>
              <td v-if="incomingInvoice.flgUseCostPositionNumber"></td>

              <td>
                <table class="rest-sum-info">
                  <tbody>
                  <tr>
                    <td class="text-right">Rest:</td>
                    <td class="text-right rest-sum-info-value">{{ calculateRest(group.invoiceGroup.id, true) }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Von:</td>
                    <td class="text-right rest-sum-info-value">
                      {{ formatAmountAndCurrency(group.amount ?? 0, incomingInvoice.currency) }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>

              <td>
                <v-btn
                  v-if="(calculateRest(group.invoiceGroup.id, false) > -2 && calculateRest(group.invoiceGroup.id, false) !== 0 && calculateRest(group.invoiceGroup.id, false) < 2 && !!getLastPositionWithAmountForGroup(group.invoiceGroup.id))"
                  icon="mdi-calculator"
                  size="small"
                  @click="addRestForGroup(group.invoiceGroup.id)"
                >
                  <v-icon icon="mdi-plus-box"></v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    Restbetrag der letzten Position mit Kosten hinzufügen
                  </v-tooltip>
                </v-btn>
              </td>
            </template>
          </template>
        </tr>
        </tfoot>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.link {
  cursor: pointer;
}

.selected-group-item {
  background: #eee;
}

.selected-group-row {
  background: #ccc;
}

table.rest-sum-info {
  width: 100%;
}

table.rest-sum-info td {
  border: 0 !important;
}

table.rest-sum-info td.rest-sum-info-value {
  width: 1%;
  white-space: nowrap;
  padding-left: 10px;
}

.v-card-item {
  padding: 15px !important;;
  background: #e0e0e0 !important;;
}

.v-card-title {
  font-size: 14px !important;
  line-height: normal !important;;
}

.v-btn.btn-remove-tab {
  margin-left: 10px;
}
</style>

<script lang="ts" setup>

import { ref }                         from "vue"
import IncomingInvoice                 from "@/interfaces/IncomingInvoice"
import CurrencyInput                   from "@/components/CurrencyInput.vue"
import { useNumberFormatter }          from "@/helpers/formatters"
import { useIncomingInvoiceFunctions } from "@/helpers/incomingInvoice"
import { useString }                   from "@/helpers/string"

const props = defineProps<{
  incomingInvoice: IncomingInvoice,
}>()

const calculationError = ref("")
const showDetailsForShows = ref(true)

const {
  calculateSum,
  calculateFormula,
  checkAndSetGroup,
  checkIfAllSelectedPositionsAreIdentical,
  selectedConcerts,
  selectedNoShowProjects,
  anyConcertSelected,
  anyNoShowProjectsSelected
} = useIncomingInvoiceFunctions()
const { formatDateRange, formatAmountAndCurrency } = useNumberFormatter()

const { nl2br } = useString()

const numberOfSelectedConcerts = () => {
  if (props.incomingInvoice.incomingInvoiceTours.length === 0) {
    return 0
  }

  return selectedConcerts(props.incomingInvoice).length
}

const numberOfSelectedConcertsAsText = () => {
  let cnt = numberOfSelectedConcerts()
  let cntNsp = selectedNoShowProjects(props.incomingInvoice).length

  return "Es wurden "
    + cnt + (cnt !== 1 ? " Konzerte" : " Konzert")
    + " und " + cntNsp + (cntNsp !== 1 ? " Non-Show-Projects" : " Non-Show-Project") + " ausgewählt"
}

const selectAllForTour = (tourId, newValue) => {
  props.incomingInvoice.incomingInvoiceTours.forEach((tour) => {
    if (tour.tourId === tourId) {
      tour.incomingInvoiceContainers.forEach((container) => {
        container.incomingInvoiceConcerts.forEach((concert) => {
          concert.isSelected = newValue.target.checked
        })
        container.selectedState = newValue.target.checked
      })
    }
  })
}

const selectAllNoShowProjects = (newValue) => {
  props.incomingInvoice.incomingInvoiceNoShowProjects.forEach(item => {
    item.isSelected = newValue.target.checked
  })
}

const selectAllConcertsForCostAssignment = (newValue) => {
  props.incomingInvoice.incomingInvoiceTours.forEach((tour) => {
    tour.incomingInvoiceContainers.forEach((container) => {
      container.incomingInvoiceConcerts.forEach((concert) => {
        if (concert.isSelected) {
          concert.isSelectedForCostAssignment = newValue.target.checked
        }
        container.selectedStateForCostAssignment = newValue.target.checked
      })
    })
  })
}

const selectAllNoShowProjectsForCostAssignment = (newValue) => {
  props.incomingInvoice.incomingInvoiceNoShowProjects.forEach(item => {
    item.isSelectedForCostAssignment = newValue.target.checked
  })
}


const calculateRest = (groupId, formatOutput) => {
  let sum = 0

  props.incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
    tourItem.incomingInvoiceContainers.forEach((container) => {
      container.incomingInvoiceConcerts.forEach((concertItem) => {
        if (concertItem.isSelected) {
          concertItem.incomingInvoicePositions.forEach((position) => {
            if (position.groupId === groupId && position.amount) {
              sum += parseFloat(position.amount.toFixed(6))
            }
          })
        }
      })
    })
  })

  props.incomingInvoice.incomingInvoiceNoShowProjects.forEach((noShowProjectItem) => {
    if (noShowProjectItem.isSelected) {
      noShowProjectItem.incomingInvoicePositions.forEach((position) => {
        if (position.groupId === groupId && position.amount) {
          sum += parseFloat(position.amount.toFixed(6))
        }
      })
    }
  })

  let group = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
    return groupId === group.invoiceGroup?.id
  })

  let rest = (group[0].amount ?? 0) - parseFloat(sum.toFixed(6))

  if (!formatOutput) {
    return parseFloat(rest.toFixed(6))
  }

  return formatAmountAndCurrency(rest, props.incomingInvoice.currency, 6)
}

const addRestForGroup = (groupId) => {
  let rest = calculateRest(groupId, false)
  let pos = getLastPositionWithAmountForGroup(groupId)
  let cont = getLastContainerWithAmountForGroup(groupId)

  // this sets the value for the last position
  if (pos !== null) {
    pos.amount += rest
  }

  // get the total sum
  let total = 0
  cont.incomingInvoiceConcerts.forEach(tmpCon => {
    tmpCon.incomingInvoicePositions.forEach(tmpPos => {
      if (tmpPos.groupId === groupId) {
        total += tmpPos.amount
      }
    })
  })

  // get the container position
  let contPos = cont.incomingInvoicePositions.filter(tmpPos => {
    return tmpPos.groupId === groupId
  }).find((x) => typeof x !== "undefined")

  // set the container position
  contPos.amount = total
}

const getLastPositionWithAmountForGroup = (groupId) => {
  let pos = null

  props.incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
    tourItem.incomingInvoiceContainers.forEach((container) => {
      container.incomingInvoiceConcerts.forEach((concertItem) => {
        if (concertItem.isSelected) {
          concertItem.incomingInvoicePositions.forEach((position) => {
            if (position.groupId === groupId && position.amount) {
              pos = position
            }
          })
        }
      })
    })
  })

  return pos
}

const getLastContainerWithAmountForGroup = (groupId) => {
  let cont = null

  props.incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
    tourItem.incomingInvoiceContainers.forEach((container) => {
      if (container.selectedState !== false) {
        container.incomingInvoiceConcerts.forEach((concertItem) => {
          concertItem.incomingInvoicePositions.forEach((position) => {
            if (position.groupId === groupId && position.amount > 0) {
              cont = container
            }
          })
        })
      }
    })
  })

  return cont
}

const linkToConcert = (id) => {
  return import.meta.env.VITE_CONCERTON_URL + "/concert/details?id=" + id
}

const removeTab = (index) => {
  props.incomingInvoice.incomingInvoiceTours.splice(index, 1)
}

const canRemoveTab = (tourItem) => {
  let canDelete = true

  tourItem.incomingInvoiceContainers.forEach((container) => {
    container.incomingInvoiceConcerts.forEach((concertItem) => {
      if (concertItem.isSelected) {
        canDelete = false
      }
    })
  })

  return canDelete
}

const close = (incomingInvoicePosition, incomingInvoiceContainer, type) => {
  let formula = incomingInvoicePosition.formula || null
  let res = ""
  calculationError.value = ""
  if (formula) {
    let resObj = calculateFormula(formula)
    res = resObj.res
    calculationError.value = resObj.errorMessage
  } else {
    incomingInvoicePosition.calculatorOverlay = false
  }

  if (!calculationError || calculationError.value === "" || calculationError.value === null) {
    incomingInvoicePosition.amount = res
    incomingInvoicePosition.calculatorOverlay = false
  }

  if (type === "group" && incomingInvoiceContainer !== null) {
    setValueForGroup(incomingInvoiceContainer, incomingInvoicePosition, "amount")
  }

  if (type === "position") {
    calculateSum(incomingInvoiceContainer, incomingInvoicePosition.groupId)
  }
}

const incomingInvoiceContainerStateChange = (container) => {
  container.incomingInvoiceConcerts.forEach((concert) => {
    concert.isSelected = container.selectedState
  })
}

const incomingInvoiceContainerStateForCostAssignmentChange = (container) => {
  container.incomingInvoiceConcerts.forEach((concert) => {
    concert.isSelectedForCostAssignment = container.selectedStateForCostAssignment
  })
}

const checkOrUncheckContainer = (container) => {
  let countChecked = 0
  let countUnchecked = 0

  container.incomingInvoiceConcerts.forEach((concert) => {
    if (concert.isSelected) {
      countChecked++
    } else {
      countUnchecked++
    }
  })

  if (countUnchecked === 0) {
    container.selectedState = true
  } else if (countChecked === 0) {
    container.selectedState = false
  } else {
    container.selectedState = null
  }

  setAllValuesForContainer(container)
}

const setAllValuesForContainer = (container) => {
  container.incomingInvoicePositions.forEach(containerPosition => {

    let countSelectedConcerts = container.incomingInvoiceConcerts.filter((concert) => {
      return concert.isSelected
    }).length

    let allAmountsAreIdentical = checkIfAllSelectedPositionsAreIdentical(container, containerPosition.groupId, "amount")
    let totalAmount = 0
    container.incomingInvoiceConcerts.forEach(concert => {
      concert.incomingInvoicePositions.forEach(concertPosition => {
        if (concertPosition.groupId === containerPosition.groupId) {
          if (!concert.isSelected) {
            concertPosition.amount = null
          } else {
            totalAmount += concertPosition.amount
          }

          if (allAmountsAreIdentical && concert.isSelected) {
            if (containerPosition.amount === null) {
              concertPosition.amount = null
            } else {
              concertPosition.amount = (containerPosition.amount / countSelectedConcerts)
            }
          }
        }
      })
    })

    if (!allAmountsAreIdentical && totalAmount !== 0) {
      containerPosition.amount = totalAmount
    }

    let allDescsAreIdentical = checkIfAllSelectedPositionsAreIdentical(container, containerPosition.groupId, "desc")
    container.incomingInvoiceConcerts.forEach(concert => {
      concert.incomingInvoicePositions.forEach(concertPosition => {
        if (concertPosition.groupId === containerPosition.groupId) {
          if (allDescsAreIdentical && concert.isSelected) {
            concertPosition.desc = containerPosition.desc
          }
          if (!concert.isSelected) {
            concertPosition.desc = null
          }
        }
      })
    })

    let allNumbersAreIdentical = checkIfAllSelectedPositionsAreIdentical(container, containerPosition.groupId, "number")
    container.incomingInvoiceConcerts.forEach(concert => {
      concert.incomingInvoicePositions.forEach(concertPosition => {
        if (concertPosition.groupId === concertPosition.groupId) {
          if (allNumbersAreIdentical && concert.isSelected) {
            concertPosition.number = containerPosition.number
          }
          if (!concert.isSelected) {
            concertPosition.number = null
          }
        }
      })
    })
  })
}

const checkOrUncheckContainerForCostAssignment = (container) => {
  let countChecked = 0
  let countUnchecked = 0

  container.incomingInvoiceConcerts.forEach((concert) => {
    if (concert.isSelectedForCostAssignment) {
      countChecked++
    } else {
      countUnchecked++
    }
  })

  if (countUnchecked === 0) {
    container.selectedStateForCostAssignment = true
  } else if (countChecked === 0) {
    container.selectedStateForCostAssignment = false
  } else {
    container.selectedStateForCostAssignment = null
  }
}

const getSelectedConcertCountForContainer = (container) => {
  let selectedConcerts = container.incomingInvoiceConcerts.filter((item) => {
    return item.isSelected
  })

  if (selectedConcerts.length === container.incomingInvoiceConcerts.length) {
    return selectedConcerts.length
  }

  return selectedConcerts.length.toString() + "/" + container.incomingInvoiceConcerts.length.toString()
}

const setValueForGroup = (container, containerPosition, type) => {
  let countSelectedConcerts = container.incomingInvoiceConcerts.filter((concert) => {
    return concert.isSelected
  }).length

  let allIdentical = checkIfAllSelectedPositionsAreIdentical(container, containerPosition.groupId, type)
  if (allIdentical) {
    container.incomingInvoiceConcerts.forEach((concert) => {
      if (concert.isSelected) {
        concert.incomingInvoicePositions.forEach((pos) => {
          if (pos.groupId === containerPosition.groupId) {
            if (type === "amount") {
              if ((containerPosition.amount ?? null) === null) {
                pos.amount = null
              } else {
                pos.amount = (containerPosition.amount / countSelectedConcerts)
              }
            }
            if (type === "number") {
              pos.number = containerPosition.number
            }
            if (type === "desc") {
              pos.desc = containerPosition.desc
            }
          }
        })
      }
    })
  }
}

</script>
