<template>
  <div v-if="options">
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel()"
    >
      <v-card>
        <v-toolbar
          :color="options.color"
          dense
          flat
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text
          v-show="!!message"
          class="pa-4"
        >
          <span v-html="message?.replace(/\n/g, '<br />')"></span>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            size="small"
            variant="outlined"
            @click.native="agree()"
          >
            Ok
          </v-btn>
          <v-btn
            size="small"
            variant="outlined"
            @click.native="cancel()"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from "vue";

interface Options {
  color: string;
  width: number;
  zIndex: number;
}

export default defineComponent({
  setup() {
    const state = reactive({
      dialog: false,
      resolve: (val: boolean) => {},
      reject: (val: boolean) => {},
      message: "",
      title: "",
      options: {
        color: "primary",
        width: 290,
        zIndex: 20000,
      },
    });

    const open = (title: string, message: string, options: Options) => {
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.options = Object.assign(state.options, options);
      return new Promise<boolean>((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const agree = () => {
      state.resolve(true);
      state.dialog = false;
    };

    const cancel = () => {
      state.resolve(false);
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      open,
      agree,
      cancel,
    };
  },
});
</script>
