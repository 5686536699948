export function useIncomingInvoiceFunctions() {

  /**
   * Calculate and set the sum for a given container and a given groupId.
   * @param incomingInvoiceContainer
   * @param groupId
   */
  const calculateSum = (incomingInvoiceContainer, groupId) => {
    let amount = incomingInvoiceContainer.incomingInvoiceConcerts
      .flatMap(concert => concert.incomingInvoicePositions)
      .filter(position => position.groupId === groupId)
      .reduce((sum, position) => sum + (position.amount ?? 0), 0)

    incomingInvoiceContainer.incomingInvoicePositions
      .filter(position => position.groupId === groupId)
      .forEach(position => {
        if (amount === 0) {
          position.amount = null
        } else {
          position.amount = amount
        }
      })
  }

  /**
   * Get all selected concerts.
   * @param incomingInvoice
   */
  const selectedConcerts = (incomingInvoice) => {
    if (incomingInvoice.incomingInvoiceTours.length === 0) {
      return []
    }

    let selectedConcerts = []
    incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
      tourItem.incomingInvoiceContainers.forEach((container) => {
        container.incomingInvoiceConcerts.forEach(concert => {
          if (concert.isSelected) {
            selectedConcerts.push(concert)
          }
        })
      })
    })

    return selectedConcerts
  }

  /**
   * Get all selected concerts for assignment.
   * @param incomingInvoice
   */
  const selectedConcertsForAssignment = (incomingInvoice) => {
    return selectedConcerts(incomingInvoice).filter((concert) => {
      return concert.isSelectedForCostAssignment
    })
  }

  /**
   * Check if any concerts are selected.
   * @param incomingInvoice
   */
  const anyConcertSelected = (incomingInvoice) => {
    return selectedConcerts(incomingInvoice).length > 0
  }

  /**
   * Check if any concerts are selected for assignment.
   * @param incomingInvoice
   */
  const anyConcertSelectedForAssignment = (incomingInvoice) => {
    return selectedConcertsForAssignment(incomingInvoice).length > 0
  }

  /**
   * Get all selected no show projects.
   * @param incomingInvoice
   */
  const selectedNoShowProjects = (incomingInvoice) => {
    return incomingInvoice.incomingInvoiceNoShowProjects.filter((item) => {
      return item.isSelected
    })
  }

  /**
   * Get all selected no show projects for assignment.
   * @param incomingInvoice
   */
  const selectedNoShowProjectsForAssignment = (incomingInvoice) => {
    return selectedNoShowProjects(incomingInvoice).filter((item) => {
      return item.isSelectedForCostAssignment
    })
  }

  /**
   * Check if any no show projects are selected.
   * @param incomingInvoice
   */
  const anyNoShowProjectsSelected = (incomingInvoice) => {
    return selectedNoShowProjects(incomingInvoice).length > 0
  }

  /**
   * Check if any no show projects are selected for assignment.
   * @param incomingInvoice
   */
  const anyNoShowProjectsSelectedForAssignment = (incomingInvoice) => {
    return selectedNoShowProjectsForAssignment(incomingInvoice).length > 0
  }

  const calculateFormula = (formula) => {
    let res = ""
    let errorMessage = ""
    if (formula.match(/^[\d\+\-,\.\(\)\*\/]+$/i) !== null) {
      let parts = formula.split(/\+|\-|\(|\)|\*|\//)
      let isGermanNumbers = true
      let isEnglishNumbers = false

      parts.forEach((part) => {
        if (part !== "") {
          if (part.match(/^-?\d{1,6}(?:\.\d{3})*(?:,\d+)?$/) === null) {
            isGermanNumbers = false
          }
        }
      })

      if (!isGermanNumbers) {
        // now check for english numbers
        isEnglishNumbers = true

        parts.forEach((part) => {
          if (part !== "") {
            if (part.match(/^-?\d{1,6}(?:,\d{3})*(?:\.\d+)?$/) === null) {
              isEnglishNumbers = false
            }
          }
        })
      }

      if (isGermanNumbers) {
        formula = formula.replace(/\./g, "").replace(/,/g, ".")
      }

      if (isGermanNumbers || isEnglishNumbers) {
        try {
          res = eval(formula)
          errorMessage = ""
        } catch (e) {
          errorMessage = "Ungültige Formel"
        }
      } else {
        errorMessage = "Nur englische oder deutsche Zahlen"
      }
    } else {
      errorMessage = "Ungültige Zeichen"
    }

    return { res: res, errorMessage: errorMessage}
  }

  const checkAndSetGroup = (container, groupId, type) => {
    // this function should only be called for 'desc' and 'number' (still works though)

    let allEmpty = true
    let tmpVal = null

    container.incomingInvoiceConcerts.forEach(concert => {
      if (concert.isSelected) {
        concert.incomingInvoicePositions.forEach(position => {
          if (position.groupId === groupId) {
            let currVal = position[type] ?? null
            if (currVal !== null && currVal !== '') {
              allEmpty = false
              tmpVal = currVal
            }
          }
        })
      }
    })

    let containerPosition = container.incomingInvoicePositions.filter(pos => {
      return pos.groupId === groupId
    }).find((x) => typeof x !== "undefined")

    if (allEmpty) {
      containerPosition[type] = null
    } else if (checkIfAllSelectedPositionsAreIdentical(container, groupId, type)) {
      containerPosition[type] = tmpVal
    }
  }

  function checkIfAllSelectedPositionsAreIdentical(container, groupId, type) {
    let val = "not set"
    let allIdentical = true

    let countSelectedConcerts = 0

    container.incomingInvoiceConcerts.forEach((concert) => {
      concert.incomingInvoicePositions.forEach((position) => {
        if (position.groupId === groupId && concert.isSelected) {
          countSelectedConcerts++
          let tmpVal = null
          if (type === "desc" && !!position && position.desc !== null) {
            tmpVal = position.desc ?? null
          }
          if (type === "number" && position.number !== null) {
            tmpVal = position.number ?? null
          }
          if (type === "amount" && position.amount !== null) {
            tmpVal = position.amount ?? null
          }

          if (val === "not set") {
            val = tmpVal
          }

          if (!compareValues(val, tmpVal)) {
            allIdentical = false
          }

          if (compareValues(val, tmpVal)) {
            val = tmpVal
          }
        }
      })
    })

    if (countSelectedConcerts === 1) {
      return true
    }

    return allIdentical
  }

  function compareValues(value1, value2) {
    if (( value1 === "" && value2 === null ) || ( value1 === null && value2 === "" )) {
      return true
    } else {
      return value1 === value2
    }
  }

  return {
    calculateSum,
    selectedConcerts,
    selectedConcertsForAssignment,
    anyConcertSelected,
    anyConcertSelectedForAssignment,
    selectedNoShowProjects,
    selectedNoShowProjectsForAssignment,
    anyNoShowProjectsSelected,
    anyNoShowProjectsSelectedForAssignment,
    calculateFormula,
    checkAndSetGroup,
    checkIfAllSelectedPositionsAreIdentical,
    compareValues
  }
}
