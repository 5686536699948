<template>
  <template v-if="!isLoading">
    <div class="d-flex flex-row section-header">
      <h3 class="flex-grow-1">
        Attributstyp anlegen
      </h3>
    </div>

    <RequirementAttributeTypeItemEdit
      :editId="editId"
      :editMode="editId !== 0"
      :isEdited="editId === 0 || editId === -1"
      :requirementAttributeType="newRequirementAttributeType"
      @refresh="refresh"
      @resetEdit="resetEdit"
      @setEdit="setEdit"
      :tabindex="1"
    ></RequirementAttributeTypeItemEdit>

    <h3 class="mt-7 mb-3">Attributstypen-Übersicht</h3>
    <template v-for="element in allRequirementAttributeType">
      <RequirementAttributeTypeItemEdit
        :editId="editId"
        :editMode="editId !== 0"
        :isEdited="editId === element.id"
        :requirementAttributeType="element"
        @refresh="refresh"
        @resetEdit="resetEdit"
        @setEdit="setEdit"
        :tabindex="tabindex"
      ></RequirementAttributeTypeItemEdit>
    </template>

  </template>
</template>

<script lang="ts" setup>

import { onMounted, reactive, Ref, ref, watch } from "vue"
import RequirementService                       from "@/api/RequirementService"
import draggable                                from "vuedraggable"
import RequirementAttributeTypeItemEdit         from "@/components/RequirementAttributeTypeItemEdit"
import RequirementAttributeType                 from "@/interfaces/RequirementAttributeType"

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())

const isLoading = ref(true)

const tab = ref("container")
const editId = ref()
const tabindex = ref(10)

const newRequirementAttributeType = new reactive({} as RequirementAttributeType)
const allRequirementAttributeType: Ref<RequirementAttributeType[]> = ref([])

const dragging = ref(false)

const props = defineProps<{
  requirementTab?: string
}>()

const emit = defineEmits(["setEditMode", "refresh"])

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const init = async () => {
  newRequirementAttributeType.id = -1

  await requirementService.value.getAllAttributeType().then(data => {
    if (data.success) {
      allRequirementAttributeType.value = data.data
    }
  })

  editId.value = 0

  isLoading.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = async (val) => {
  await init()

  emit("refresh", {})
}

watch(() => editId.value,
  () => {
    emit("setEditMode", { editMode: (editId.value !== 0) })
  }
)

</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
